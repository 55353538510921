
class LocalStorageService {


    exists( key)
    {
        const result = this.get(key);

        if( result === null )
            return( false );
        else    
            return( true );

    }

    set( key, value )
    {
        return localStorage.setItem(key,value);
    }

    get( key )
    {
        return localStorage.getItem(key);
    }   

    delete( key )
    {
        return localStorage.removeItem(key);
    }

    clear()
    {
        return localStorage.clear();
    }

}

export default LocalStorageService;