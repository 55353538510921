class LuCoreServiceLocations {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    
    async timezones() {

        return( 
            this.lucore.axios.get( '/locations/timezones', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in timezones get",e);
                return( e );

            })
        );
    }


    async list( params ) {

        return( 
            this.lucore.axios.get( '/locations', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in locations",e);
                return( e );

            })
        );
    }

    async nextSnapshotGroup( params ) {
        //next-snapshot-group

        return( 
            this.lucore.axios.get( '/locations/next-snapshot-group', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in nextSnapshotGroup",e);
                return( e );
            })
        );

    }
    
    async get(locationId) {

        return( 
            this.lucore.axios.get( '/locations/'+locationId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in locations get",e);
                return( e );

            })
        );
    }

    
    async comments(locationId) {

        return( 
            this.lucore.axios.get( '/locations/'+locationId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( e );
            })
        );
    }

    
    
    async addComment(locationId,commentText) {

        return( 
            this.lucore.axios.post( '/locations/'+locationId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( e );
            })
        );
    }



    /**
     * 
     * @param {account_id,name,description,phone,address,city,region,postal_code,country,latitude,longitude} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/locations', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create location",e);
                return( e );

            })
        );
    }

    
    
    /**
     * Update the location
     * @param {locationId} locationId
     * @param {account_id,name,description,phone,address,city,region,postal_code,country,latitude,longitude} params 
     */
    async update(locationId,params) {

        return( 
            this.lucore.axios.put( '/locations/'+locationId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in location update",e);
                return( e );

            })
        );
    }

    
    /**
     * Update the location
     * @param {locationId} locationId
     * @param {account_id,name,description,phone,address,city,region,postal_code,country,latitude,longitude} params 
     */
    async setBackupImageObject(locationId,imageId) {

        return( 
            this.lucore.axios.post( '/locations/'+locationId+'/set-street-view-backup-image', {image_id:imageId} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in location update",e);
                return( e );

            })
        );
    }


    /**
     * 
     * @param {locationId,location_default} params `location_default` is either 1 or 0
     */
    async setDefault(locationId,location_default) {

        const params = {
            location_default : location_default
        }

        return( 
            this.lucore.axios.put( '/locations/'+locationId+'/setDefault', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in location setDefault",e);
                return( e );

            })
        );
    }
    
    
    /**
     * Update the location active state
     * 
     */
    async setActive(locationId,active) {

        const params = {
            active: active
        }

        return( 
            this.lucore.axios.put( '/locations/'+locationId+'/setActive', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in location setActive",e);
                return( {} );

            })
        );
    }

    
    /**
     * 
     * @param {locationId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(locationId,file) {
        return( 
            this.lucore.setPrimaryImage('locations',locationId,file,"Profile")
        )
    }



}

export default LuCoreServiceLocations;