class LuCoreServiceFonts {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    
    async listAdmin(params) {
        return( 
            this.lucore.axios.get( '/fonts/list-admin', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in listAdmin",e);
                return( {} );

            })
        );    
    }   


    async list(cacheable) {

        const cacheKey = 'LuCoreServiceFonts_fonts';
        
        return( 

            this.lucore.cacheable( cacheKey, cacheable ).then( cachedResponse => {

                if( cachedResponse )
                        return( cachedResponse );                        

                return( 
                    this.lucore.axios.get( '/fonts', {} )
                    .then(response => {
                        this.lucore.storeCache( cacheKey, response.data );
                        return( response.data );
                    })
                    .catch(e => {
                        this.lucore.logError("Error in list",e);
                        return( {} );
        
                    })
                );    
            })
        )
    }   

    
    async setPublic(fontLcuid,isPublic) {
        return( 
            this.lucore.axios.put( '/fonts/'+fontLcuid+'/set-public-status', { 
                public: isPublic ? 1 : 0
             } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setPublic",e);
                return( {} );

            })
        );    
    }   

}

export default LuCoreServiceFonts;