class LuCoreServiceHovers {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list(params) {

        return( 
            this.lucore.axios.get( '/hovers', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers list",e);
                return( {} );

            })
        );
    }
    
    
    async get(hoverId) {

        return( 
            this.lucore.axios.get( '/hovers/'+hoverId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers get",e);
                return( {} );

            })
        );
    }   

    
    async getRun(hoverRunId) {

        return( 
            this.lucore.axios.get( '/hovers/run/'+hoverRunId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers getRun",e);
                return( {} );

            })
        );
    }   

        
    async getRunLogs(hoverRunId) {

        return( 
            this.lucore.axios.get( '/hovers/run/'+hoverRunId+'/logs', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers getRunLogs",e);
                return( e );

            })
        );
    }   

    
    async runs(hoverId, params) {

        return( 
            this.lucore.axios.get( '/hovers/'+hoverId+'/runs', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers runs",e);
                return( {} );

            })
        );
    }   

    async hoverTests(hoverId, params) {

        return( 
            this.lucore.axios.get( '/hovers/'+hoverId+'/hover-tests', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers hoverTests",e);
                return( {} );

            })
        );
    }   

    
    
    async getHoverTest(hoverTestId) {

        return( 
            this.lucore.axios.get( '/hovers/hover-test/'+hoverTestId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers getHoverTest",e);
                return( {} );

            })
        );
    }   


    
    async getHoverTestRuns(hoverTestId) {

        return( 
            this.lucore.axios.get( '/hovers/hover-test/'+hoverTestId+'/runs', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers getHoverTestRuns",e);
                return( {} );

            })
        );
    }   


    
    async comments(hoverId) {

        return( 
            this.lucore.axios.get( '/hovers/'+hoverId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(hoverId,commentText) {

        return( 
            this.lucore.axios.post( '/hovers/'+hoverId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }

    async scraperClasses() {

        return( 
            this.lucore.axios.get( '/hovers/scrapers/classes', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in scraperClasses",e);
                return( {} );
            })
        );
    }



    
    /**
     * Create the hover
     * * @param {} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/hovers', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers create",e);
                return( e );

            })
        );
    }


    
    /**
     * Update the hover
     * * @param {} params 
     */
    async update(hoverId,params) {

        return( 
            this.lucore.axios.put( '/hovers/'+hoverId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers update",e);
                return( e );

            })
        );
    }

        

    /**
     * Update the hover active state
     * 
     */
    async setActive(hoverId,active) {

        const params = {
            active: active
        }

        return( 
            this.lucore.axios.put( '/hovers/'+hoverId+'/setActive', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hovers update",e);
                return( {} );

            })
        );
    }

    
    /**
     * Update the hover setStatus
     * 
     */
    async setStatus(hoverId,status) {

        const params = {
            status: status
        }

        return( 
            this.lucore.axios.put( '/hovers/'+hoverId+'/setStatus', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hover setStatus",e);
                return( e );

            })
        );
    }



}

export default LuCoreServiceHovers;