class LuCoreServiceAgencies {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list(params) {

        return( 
            this.lucore.axios.get( '/agencies', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in agencies",e);
                return( {} );

            })
        );
    }

    
    async roles() {

        return( 
            this.lucore.axios.get( '/agencies/roles', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in roles",e);
                return( {} );

            })
        );
    }

    
    async agencyClasses() {

        return( 
            this.lucore.axios.get( '/agencies/agency-classes', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in agency-classes",e);
                return( {} );

            })
        );
    }


    async operatorProducts() {

        return( 
            this.lucore.axios.get( '/agencies/operator-products', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in operatorProducts",e);
                return( {} );

            })
        );
    }

    
    async accounts(agencyId) {

        return( 
            this.lucore.axios.get( '/agencies/'+agencyId+'/accounts', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in accounts",e);
                return( {} );
            })
        );
    }

    async applications(agencyId) {

        return( 
            this.lucore.axios.get( '/agencies/'+agencyId+'/applications', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applications",e);
                return( {} );
            })
        );
    }

    async get(agencyId, cacheable) {

        const cacheKey = 'LuCoreServiceAgencies_get_'+agencyId;
        
        return( 

            this.lucore.cacheable( cacheKey, cacheable ).then( cachedResponse => {

                if( cachedResponse )
                    return( cachedResponse );

                return(
                    
                    this.lucore.axios.get( '/agencies/'+agencyId, {})
                    .then(response => {
                        this.lucore.storeCache( cacheKey, response.data.agency );
                        return( response.data.agency );
                    })
                    .catch(e => {
                        this.lucore.logError("Error in agencies get",e);
                        return( {} );
        
                    })    
                )
                
            })

        )
    }

        
    async childAgencies(agencyId) {

        return( 
            this.lucore.axios.get( '/agencies/'+agencyId+'/child-agencies', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in childAgencies",e);
                return( {} );
            })
        );
    }





    async invoices(id,page) {

        return(
            this.lucore.axios.get( '/agencies/'+id+'/invoices', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoices",e);
                return(e);
            })
        );
    }



    async generateNextInvoice(id) {

        return(
            this.lucore.axios.post( '/agencies/'+id+'/invoices/generate-next-invoice')
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoices",e);
                return(e);
            })
        );
    }


    async cards(id) {

        return(
            this.lucore.axios.get( '/agencies/'+id+'/cards', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in cards",e);
                return(e);
            })
        );
    }



    async payments(id,page) {

        return(
            this.lucore.axios.get( '/agencies/'+id+'/payments', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in payments",e);
                return(e);
            })
        );
    }

    async addPayment(id,params) {

        return(
            this.lucore.axios.post( '/agencies/'+id+'/payments', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addPayment",e);
                return(e);
            })
        );
    }



    async billingPlans(id,page) {

        return(
            this.lucore.axios.get( '/agencies/'+id+'/billing-plans', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlans",e);
                return(e);
            })
        );
    }


    async addBillingPlan(id,params) {

        return(
            this.lucore.axios.post( '/agencies/'+id+'/billing-plans', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addBillingPlan",e);
                return(e);
            })
        );
    }


    async addBillingPlanFromTemplate(id,params) {

        return(
            this.lucore.axios.post( '/agencies/'+id+'/billing-plan-from-template', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addBillingPlan",e);
                return(e);
            })
        );
    }



    async stories(id,page) {

        return( 
            this.lucore.axios.get( '/agencies/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }
    
    async comments(agencyId) {

        return( 
            this.lucore.axios.get( '/agencies/'+agencyId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(agencyId,commentText) {

        return( 
            this.lucore.axios.post( '/agencies/'+agencyId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }

    async setIsDemo(id) {

        return( 
            this.lucore.axios.put( '/agencies/'+id+'/is-demo', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setIsDemo",e);
                return( {} );
            })
        );
    }

    
    
    async removeIsDemo(id) {

        return( 
            this.lucore.axios.delete( '/agencies/'+id+'/is-demo' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeIsDemo",e);
                return( {} );
            })
        );
    }


    /**
     * 
     * @param {name} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/agencies', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create agency",e);
                return( {} );

            })
        );
    }

    
    
    /**
     * Update the agency
     * * @param {agencyId} params 
     */
    async update(agencyId,params) {

        return( 
            this.lucore.axios.put( '/agencies/'+agencyId+'/admin-update', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in agency update",e);
                return( e );

            })
        );
    }

    

    /**
     * Update the agency active state
     * 
     */
    async setActive(agencyId,active) {

        const params = {
            active: active
        }

        return( 
            this.lucore.axios.put( '/agencies/'+agencyId+'/setActive', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in agency setActive",e);
                return( {} );

            })
        );
    }

    
    async users(agencyId) {

        return( 
            this.lucore.axios.get( '/agencies/'+agencyId+'/users', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in agencies.users",e);
                return( {} );

            })
        );
    }

    
    

    
    async following(agencyId) {

        return( 
            this.lucore.axios.get( '/agencies/'+agencyId+'/following', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in following",e);
                return( {} );
            })
        );
    }

    
    
    async follow(agencyId) {

        return( 
            this.lucore.axios.post( '/agencies/'+agencyId+'/following', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in follow",e);
                return( {} );
            })
        );
    }

    
    
    async removeFollowing(agencyId) {

        return( 
            this.lucore.axios.delete( '/agencies/'+agencyId+'/following' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeFollowing",e);
                return( {} );
            })
        );
    }



    
    
    async history(agencyId) {

        return( 
            this.lucore.axios.get( '/agencies/'+agencyId+'/history', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in history",e);
                return( {} );
            })
        );
    }

    

    
    
    async tasks(agencyId) {

        return( 
            this.lucore.axios.get( '/agencies/'+agencyId+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }

    
    /**
     * 
     * @param {*} agencyId 
     * @param {title,description,due_at,class} taskParams 
     */
    async addTask(agencyId,taskParams) {

        return( 
            this.lucore.axios.post( '/agencies/'+agencyId+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }
    

    
    /**
     * 
     * @param {agencyId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(agencyId,file) {
        return( 
            this.lucore.setPrimaryImage('agencies',agencyId,file,"Profile")
        )
    }



    async driveTemplates(id) {

        return(
            this.lucore.axios.get( '/agencies/'+id+'/drive-templates', {
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in driveTemplates",e);
                return(e);
            })
        );
    }



}

export default LuCoreServiceAgencies;
