class LuCoreServiceApplications {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    
    
    async applicationClasses() {

        return( 
            this.lucore.axios.get( '/applications/application-classes', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applicationClasses",e);
                return( e );
            })
        );
    }

    async applicationCapabilities() {

        return( 
            this.lucore.axios.get( '/applications/application-capabilities', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applicationCapabilities",e);
                return( e );
            })
        );
    }
    
    
    async applicationPermissions() {

        return( 
            this.lucore.axios.get( '/applications/application-permissions', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applicationPermissions",e);
                return( e );
            })
        );
    }

    async list( params ) {

        return( 
            this.lucore.axios.get( '/applications', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applications",e);
                return( {} );

            })
        );
    }

    
    async roles() {

        return( 
            this.lucore.axios.get( '/applications/roles', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in roles",e);
                return( {} );

            })
        );
    }

    async get(id) {

        return( 
            this.lucore.axios.get( '/applications/'+id, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in application get",e);
                return( {} );

            })
        );    
    }
    
    
    
    async setIsDemo(id) {

        return( 
            this.lucore.axios.put( '/applications/'+id+'/is-demo', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setIsDemo",e);
                return( {} );
            })
        );
    }

    
    
    async removeIsDemo(id) {

        return( 
            this.lucore.axios.delete( '/applications/'+id+'/is-demo' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeIsDemo",e);
                return( {} );
            })
        );
    }


   

    
    async tasks(id) {

        return( 
            this.lucore.axios.get( '/applications/'+id+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }


    async addTask(id,taskParams) {

        return( 
            this.lucore.axios.post( '/applications/'+id+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }


    async create(params) {

        return( 
            this.lucore.axios.post( '/applications', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create",e);
                return( e );

            })
        );
    }

    async update(id,params) {

        return( 
            this.lucore.axios.put( '/applications/'+id, params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create application update",e);
                return( e );

            })
        );
    }

    async setPrimaryImage(id,file) {
        return( 
            this.lucore.setPrimaryImage('applications',id,file,"Profile")
        )
    }

    

    
    async users(id) {

        return( 
            this.lucore.axios.get( '/applications/'+id+'/users', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applications.users",e);
                return( {} );

            })
        );
    }

    
    
    async accounts(id) {

        return( 
            this.lucore.axios.get( '/applications/'+id+'/accounts', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applications.accounts",e);
                return( {} );

            })
        );
    }
    

    
    
    async agencies(id) {

        return( 
            this.lucore.axios.get( '/applications/'+id+'/agencies', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applications.agencies",e);
                return( {} );

            })
        );
    }
    
    

    async setStatus(id,status) {

        const params = {
            status: status
        }

        return( 
            this.lucore.axios.put( '/applications/'+id+'/setStatus', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applications setStatus",e);
                return( {} );

            })
        );
    }


    async addCapability(id,capabilityClass) {

        return( 
            this.lucore.axios.post( '/applications/'+id+'/capabilities', {capability_class: capabilityClass})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addCapability",e);
                return( e );
            })
        );
    }

    
    
    async removeCapability(id,capabilityId) {

        return( 
            this.lucore.axios.delete( '/applications/'+id+'/capabilities/'+capabilityId )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeCapability",e);
                return( {} );
            })
        );
    }



}

export default LuCoreServiceApplications;