import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import LuCoreConfig, {LuCoreServiceSettings} from 'config/LuCoreConfig';
import qs from 'qs';
import LocalStorageService from 'services/storage/LocalStorageService';
import LuCoreServiceAccounts from './LuCoreServiceAccounts';
import LuCoreServiceAgencies from './LuCoreServiceAgencies';
import LuCoreServiceAnalytics from './LuCoreServiceAnalytics';
import LuCoreServiceApplications from './LuCoreServiceApplications';
import LuCoreServiceCategories from './LuCoreServiceCategories';
import LuCoreServiceComments from './LuCoreServiceComments';
import LuCoreServiceDigitalBoards from './LuCoreServiceDigitalBoards';
import LuCoreServiceDriveTemplates from './LuCoreServiceDriveTemplates';
import LuCoreServiceFonts from './LuCoreServiceFonts';
import LuCoreServiceHovers from './LuCoreServiceHovers';
import LuCoreServiceInventoryExports from './LuCoreServiceInventoryExports';
import LuCoreServiceInventoryFeedProviders from './LuCoreServiceInventoryFeedProviders';
import LuCoreServiceInventoryFeeds from './LuCoreServiceInventoryFeeds';
import LuCoreServiceInventoryItems from './LuCoreServiceInventoryItems';
import LuCoreServiceImages from './LuCoreServiceImages';
import LuCoreServiceLocations from './LuCoreServiceLocations';
import LuCoreServiceMassEmails from './LuCoreServiceMassEmails';
import LuCoreServiceSupport from './LuCoreServiceSupport';
import LuCoreServiceSearch from './LuCoreServiceSearch';
import LuCoreServiceStories from './LuCoreServiceStories';
import LuCoreServiceTasks from './LuCoreServiceTasks';
import LuCoreServiceUsers from './LuCoreServiceUsers';
import LuCoreServiceObjects from './LuCoreServiceObjects';
import LuCoreServiceLogs from './LuCoreServiceLogs';
import LuCoreServiceInventoryPhotos from './LuCoreServiceInventoryPhotos';
import LuCoreServiceQueue from './LuCoreServiceQueue';
import LuCoreServiceReports from './LuCoreServiceReports';

import LuCoreServiceInvoices from './LuCoreServiceInvoices';
import LuCoreServicePayments from './LuCoreServicePayments';
import LuCoreServiceBillingPlans from './LuCoreServiceBillingPlans';
import LuCoreServiceBillingPlanTemplates from './LuCoreServiceBillingPlanTemplates';
import LuCoreServiceNotifications from './LuCoreServiceNotifications';

class LuCoreService {

    config;
    axios;
    token;
    cache;
    cachePending;

    accounts;
    agencies;
    analytics;
    applications;
    categories;    
    comments;
    digitalBoards;
    driveTemplates;
    fonts;
    hovers;
    inventoryExports;
    inventoryFeedProviders;
    inventoryFeeds;
    inventoryItems;
    images;
    massEmails;
    objects;
    support;
    search;
    stories;
    tasks;
    users;
    logs;
    photos;
    queue;
    reports;

    invoices;
    payments;
    billingPlans;
    billingPlanTemplates;
    notifications;

    constructor() {

        this.cache = [];
        this.cachePending = [];
        this.config = new LuCoreConfig();

        const lc = new LocalStorageService();

        this.token = lc.get('user_auth_token');

        let tokenString = '';

        if( this.token )
            tokenString = this.token;

        this.axios = rateLimit(  
                axios.create( {
                baseURL : this.config.endpoint_url + 'v'+ this.config.endpoint_version+'/',
                timeout: LuCoreServiceSettings.axios.requestTimeoutMs,
                headers: {
                    AppTokenV2: this.config.app_token,
                    AppVersion: process.env.REACT_APP_VERSION,
                    Authorization: 'Bearer '+tokenString
                }
            }),            
            {
                maxRequests : LuCoreServiceSettings.axios.maxRequestsPerSecond
            }
        );

        this.accounts = new LuCoreServiceAccounts(this);
        this.agencies = new LuCoreServiceAgencies(this);
        this.analytics = new LuCoreServiceAnalytics(this);
        this.applications = new LuCoreServiceApplications(this);
        this.categories = new LuCoreServiceCategories(this);
        this.comments = new LuCoreServiceComments(this);
        this.digitalBoards = new LuCoreServiceDigitalBoards(this);
        this.driveTemplates = new LuCoreServiceDriveTemplates(this);
        this.fonts = new LuCoreServiceFonts(this);      
        this.hovers = new LuCoreServiceHovers(this);        
        this.inventoryExports = new LuCoreServiceInventoryExports(this);
        this.inventoryFeedProviders = new LuCoreServiceInventoryFeedProviders(this);
        this.inventoryFeeds = new LuCoreServiceInventoryFeeds(this);
        this.inventoryItems = new LuCoreServiceInventoryItems(this);
        this.images = new LuCoreServiceImages(this);
        this.locations = new LuCoreServiceLocations(this);
        this.support = new LuCoreServiceSupport(this);
        this.massEmails = new LuCoreServiceMassEmails(this);
        this.objects = new LuCoreServiceObjects(this);
        this.search = new LuCoreServiceSearch(this);
        this.stories = new LuCoreServiceStories(this);
        this.tasks = new LuCoreServiceTasks(this);
        this.users = new LuCoreServiceUsers(this);
        this.logs = new LuCoreServiceLogs(this);
        this.inventoryPhotos = new LuCoreServiceInventoryPhotos(this);
        this.queue = new LuCoreServiceQueue(this);
        this.reports = new LuCoreServiceReports(this);

        this.invoices = new LuCoreServiceInvoices(this);
        this.payments = new LuCoreServicePayments(this);
        this.billingPlans = new LuCoreServiceBillingPlans(this);
        this.billingPlanTemplates = new LuCoreServiceBillingPlanTemplates(this);
        this.notifications = new LuCoreServiceNotifications(this);

    }

    logError(message, e) {

        console.error("LuCoreService : An Error Occurred : "+message);
        console.error(e);
    }

    clearToken() {
        
        const lc = new LocalStorageService();

        lc.delete('user_auth_token');

    }

    hasValidToken() {
        if( this.token )
            return( true );
        else
            return( false );
    }


    
    /**
     * Specifically log a user in as an admin - If NOT an admin, then we fail
     * @param {*} email 
     * @param {*} password 
     */
    async login( email, password) {

        const postParams = qs.stringify({
            email : email,
            password : password
        });

        //We create a new one here because we are NOT sending the bearer token
        const loginAxios = axios.create( {
            baseURL : this.config.endpoint_url + 'v'+ this.config.endpoint_version+'/',
            timeout: LuCoreServiceSettings.axios.requestTimeoutMs,
            headers: {
                AppTokenV2: this.config.app_token,
                AppVersion: process.env.REACT_APP_VERSION
            }
        });

                
        return(
            loginAxios.post( this.config.endpoint_url  + 'v'+ this.config.endpoint_version+'/login-admin', postParams )
            .then(response => {

                if( response.data.success.token )
                {
                    const lc = new LocalStorageService();

                    lc.set('user_auth_token',response.data.success.token);
                }
                

                return( response.data );
            }).catch(e => {

                this.logError("Error in login",e);

                return( {} );
            })
        );
    }

    /**
     * 
     * @param {*} file The file from a file selector component (e.g. target.files[0] )
     * @param {*} template The JSON template data in form [ {"template" : "MyImageTemplate"}, {"template" : "AnotherTemplate", "settings" : { "someSetting" : "someVal"} } ]
     */
    async uploadImage( file, template,modelUriCode,modelId ) {

        
        const data = new FormData()
        data.append('file', file)
        data.append('image_templates', JSON.stringify(template) )

        if( modelUriCode )
            data.append('model_uri_code',modelUriCode);

        if( modelId )
            data.append('model_id',modelId);

        return( 

            this.axios.post( '/uploads/image', data).then( response => {
                return( response.data );
            })
            .catch(e => {
                this.logError("Error in upload",e);
                return( e );
            })
        )
    }

    uploadImageBase64(fileBase64, template,modelUriCode,modelId ) {
        return fetch(fileBase64)
            .then(res => res.blob())
            .then(blob => {
                const data = new FormData()
                const file = new File([blob], "image.jpg");

                data.append('file', file)
                data.append('image_templates', JSON.stringify(template) )
        
                if( modelUriCode )
                    data.append('model_uri_code',modelUriCode);
        
                if( modelId )
                    data.append('model_id',modelId);
        
                return( 
        
                    this.axios.post( '/uploads/image', data).then( response => {
                        return( response.data );
                    })
                    .catch(e => {
                        this.logError("Error in upload",e);
                        return( e );
                    })
                )
            })
    }
    /**
     * 
     * @param {objectType,accountId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(objectType,id,file,templateName) {


        const tplName = templateName ? templateName : "IconResizer";

        const template = [
            { "template" : tplName }
        ]

        return( 

            this.uploadImage(file,template,objectType,id).then( response => {

                if( !response.success )
                    return( response )

                const imageId = response.image.id;

                return(                   
                    this.axios.post( '/'+objectType+'/'+id+'/primaryImage', {image_id : imageId} )
                    .then(response => {
                        return( response.data );
                    })
                    .catch(e => {
                        this.logError("Error in setPrimaryImage",e);
                        return( e );

                    })
                )
            })                         
        )
    }


    
    /**
     * 
     * @param {*} file The file from a file selector component (e.g. target.files[0] )
     * @param {*} template The JSON template data in form [ {"template" : "MyImageTemplate"}, {"template" : "AnotherTemplate", "settings" : { "someSetting" : "someVal"} } ]
     * @param {*} inventory_item_id
     * 
     */
    async uploadPhoto( file, template, inventory_item_id, photo_set ) {

        
        const data = new FormData()
        data.append('file', file)
        data.append('image_templates', JSON.stringify(template) )
        data.append('inventory_item_id',inventory_item_id);
        data.append('photo_set',photo_set);

        return( 

            this.axios.post( '/uploads/photo', data).then( response => {
                return( response.data );
            })
            .catch(e => {
                this.logError("Error in upload",e);
                return( e );
            })
        )
    }




    /**
     * 
     * 
     * Caching functions for LuCoreService
     * The caching backend for LuCoreService is simply using the
     * in-memory object to store the data
     * The data only persists in the current session
     * and DOES NOT persist between refreshes
     * 
     * 
     */


    /**
     * 
     * @param key the cache key to check for
     * @param set to false if you want to turn caching off
     * @returns null if we don't have a cache item, or, the cache item if we have one
     * If no cache is found, setCachePending is set to true
     * IF cachePending is true (for this key) then we delay 1000ms and check again if we don't find one
     */
    async cacheable( key, cacheable = false ) {

        if( !cacheable )
            return( null )


        if( this.hasCacheKey(key) )
        {
            return( this.getCache(key) );
        }

        if( this.cacheIsPending(key) )
        {

            const delay = ms => new Promise(res => setTimeout(res, ms));

            let totalChecks = 0;

            /**
             * Here we check cachePendingMaxChecks number of times to see if we have received some data yet in the cache
             */
            while( totalChecks < LuCoreServiceSettings.cache.cachePendingMaxChecks )
            {
                await delay( this.getCachePendingRaceDelay() );

                if( this.hasCacheKey(key) )
                {
                    return( this.getCache(key) );
                }

                totalChecks++;
            }
        }
        
        this.setCachePending(key);

        return( null );

    }

    /**
     * Return a random number for the delay when checking cachePending
     * This helps to reduce race conditions
     */
    getCachePendingRaceDelay()
    {
        const max = LuCoreServiceSettings.cache.cachePendingRaceDelayMs + LuCoreServiceSettings.cache.cachePendingRaceDelayFuzzMs;
        const min = LuCoreServiceSettings.cache.cachePendingRaceDelayMs - LuCoreServiceSettings.cache.cachePendingRaceDelayFuzzMs;

        return( Math.floor( Math.random() * (max - min) + min ) )
    }


    /**
     * Does key exist in the cache?
     * @param key 
     * @returns boolean
     */
    hasCacheKey(key)
    {
        if( this.cache[key] )
        {
            return( true );
        }

        return( false );
    }

    /**
     * Does key exist in the cacheIsPending?
     * @param key 
     * @returns boolean
     */
    cacheIsPending(key)
    {
        if( this.cachePending[key] )
        {
            return( true );
        }

        return( false );
    }

    
    /**
     * Set cachePending for the specified key
     * 
     * @param key 
     */
    setCachePending(key)
    {
        this.cachePending[key] = true;
    }

    /**
     * Clear cachePending for the specified key
     * @param key 
     */
    clearCachePending(key)
    {
        delete this.cachePending[key];
    }

    /**
     * Retrieve the data from the cache for the specified key
     * @param key
     */
    getCache(key)
    {
        if( !this.hasCacheKey(key) )
            return(null);

        return( this.cache[key].data );
    }

    /**
     * Store data in the cache
     * @param key the cache key
     * @param data The data to store - A JSON object
     * @param timeout How long until this data times out - Defaults to 6000 milliseconds
     * 
     */
    storeCache(key,data,timeout = LuCoreServiceSettings.cache.defaultStoreTimeoutMs)
    {
        this.cache[key] = {
            datetimestamp : Date.now(),
            timeout : timeout,
            data : data
        }        
    }

    /**
     * Deletes an item from the cache
     * @param key 
     */
    deleteCache(key)
    {
        delete this.cache[key];
    }     


}
export default (new LuCoreService() );
