class LuCoreServiceStories {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    
    
    async public({page}) {

        return( 
            this.lucore.axios.get( '/stories/public', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {

                this.lucore.logError("Error in users public",e);

                return( e );

            })
        );
    }

    
    async my({page}) {

        return( 
            this.lucore.axios.get( '/stories/my', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {

                this.lucore.logError("Error in users my",e);

                return( e );

            })
        );
    }


    
    async comments(lcuid) {

        return( 
            this.lucore.axios.get( '/stories/'+lcuid+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(lcuid,commentText) {

        return( 
            this.lucore.axios.post( '/stories/'+lcuid+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }


    
    async reactions(lcuid) {

        return( 
            this.lucore.axios.get( '/stories/'+lcuid+'/reactions', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in reactions",e);
                return( {} );
            })
        );
    }

    async addLike(lcuid) {
        this.addReaction(lcuid,"App\\LuCore\\Reactions\\LikeReactionClass")
    }
    
    
    async addReaction(lcuid,reactionClass) {

        return( 
            this.lucore.axios.post( '/stories/'+lcuid+'/reactions', { reaction_class : reactionClass} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addReaction",e);
                return( {} );
            })
        );
    }

    
    
    async removeReaction(lcuid) {

        return( 
            this.lucore.axios.delete( '/stories/'+lcuid+'/reactions' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeReaction",e);
                return( {} );
            })
        );
    }

    
    

    
    async following(lcuid) {

        return( 
            this.lucore.axios.get( '/stories/'+lcuid+'/following', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in following",e);
                return( {} );
            })
        );
    }

    
    
    async follow(lcuid) {

        return( 
            this.lucore.axios.post( '/stories/'+lcuid+'/following', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in follow",e);
                return( {} );
            })
        );
    }

    
    
    async removeFollowing(lcuid) {

        return( 
            this.lucore.axios.delete( '/stories/'+lcuid+'/following' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeFollowing",e);
                return( {} );
            })
        );
    }






}

export default LuCoreServiceStories;