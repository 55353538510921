class LuCoreServiceDriveTemplates {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list( params ) {

        return(
            this.lucore.axios.get( '/drive-templates', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in driveTemplates",e);
                return( e );

            })
        );
    }

    async get(lcuid) {

        return(
            this.lucore.axios.get( '/drive-templates/'+lcuid, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in driveTemplates get",e);
                return( e );

            })
        );
    }


    async fieldMap(lcuid) {

        return(
            this.lucore.axios.get( '/drive-templates/'+lcuid+'/field-map', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in driveTemplates fieldMap",e);
                return( e );

            })
        );
    }

}

export default LuCoreServiceDriveTemplates;
