class LuCoreServiceNotifications {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }


    async sendAccountInformationNotification(params) {

        return(
            this.lucore.axios.post( '/lac/notifications/account-information-notification',params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceQueue sendAccountInformationNotification",e);
                return( e );

            })
        );
    }


    async sendAgencyInformationNotification(params) {

        return(
            this.lucore.axios.post( '/lac/notifications/agency-information-notification',params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceQueue sendAgencyInformationNotification",e);
                return( e );

            })
        );
    }

}

export default LuCoreServiceNotifications;
