class LuCoreServiceLogs {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    
    async types() {

        return( 
            this.lucore.axios.get( '/logs/types', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceLogs list",e);
                return( e );

            })
        );
    }

    async list(identifier) {

        return( 
            this.lucore.axios.get( '/logs/get/'+identifier, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceLogs list",e);
                return( e );

            })
        );
    }

}

export default LuCoreServiceLogs;