import React, { useState, useEffect, useContext } from 'react';
import lc from '../services/lucore/LuCoreService';
import { UserContext } from '../context/UserContext';
import LocalStorageService from '../services/storage/LocalStorageService';


const AuthProvider = (props) => {

    const [ initialStateLoaded, setInitialStateLoaded ]  = useState(false);
    const [ userIsLoggedIn, setUserIsLoggedIn ]  = useState(false);

    const user = useContext(UserContext);


    useEffect(() => {
            
        if( lc.hasValidToken() )
        {

            if( !user.data )
            {
                lc.users.current().then( (data) => {


                    if( data.success )
                    {
                        const userData = data.user;
                        userData.loaded = true;

                         
                        if( userData.roles.includes("admin") )
                        {
                            const lss = new LocalStorageService();

                            let currentAccountId = null;

                            if( lss.exists("user_state_current_account_id") )
                                currentAccountId = parseInt(lss.get("user_state_current_account_id"))

                            userData.currentAccountId = currentAccountId;


                            user.setData( userData );
                            
                            setUserIsLoggedIn(true);
                            setInitialStateLoaded(true);
                        }
                        else
                        {
                            console.warn("User roles do not include admin role.  Not setting user, logged in is false");
                        }
                        
                    }       

                    setInitialStateLoaded(true);
                
                });
            }
        }
        else
        {
            console.warn("AuthProvider : no token");
            //We dont' have a token, so we need to get to the login page
            setInitialStateLoaded(true);
            setUserIsLoggedIn(false);
        }

    },[user]);

    

    if( !initialStateLoaded )
    {
        return(
            <div className="animated fadeIn pt-3 text-center">Loading...</div>
        )
    }

    if( !userIsLoggedIn )
    {
        return( props.notAuthorizedComponent);
    }
    

    return( props.authorizedComponent);

}

export default AuthProvider;