class LuCoreServiceInventoryItems {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    
    async inventoryItemClasses() {

        return( 
            this.lucore.axios.get( '/inventory-items/inventory-item-classes', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryItemClasses",e);
                return( e );
            })
        );
    }


    async list(params) {

        return( 
            this.lucore.axios.get( '/inventory-items', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryItems list",e);
                return( {} );

            })
        );
    }
    
    

    async get(inventoryItemId,params) {

        return( 
            this.lucore.axios.get( '/inventory-items/'+inventoryItemId, {params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryItems get",e);
                return( {} );

            })
        );
    }   

    
    async stories(id,page) {

        return( 
            this.lucore.axios.get( '/inventory-items/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }
    
    
    async comments(inventoryItemId) {

        return( 
            this.lucore.axios.get( '/inventory-items/'+inventoryItemId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    async addComment(inventoryItemId,commentText) {

        return( 
            this.lucore.axios.post( '/inventory-items/'+inventoryItemId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }





}

export default LuCoreServiceInventoryItems;