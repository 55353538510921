class LuCoreServiceInventoryFeedProviders {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    /**
     * Acceptable params
     *   page (the page number)
     *   per_page (the number per page)
     *   query (a search query)
     */
    async list( params ) {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventory-feed-providers",e);
                return( {} );

            })
        );
    }


    async get(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers/'+inventoryFeedProviderId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeedProvider get",e);
                return( {} );

            })
        );
    }

    
    
    async comments(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers/'+inventoryFeedProviderId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    async roles() {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers/roles', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in feed-providers roles",e);
                return( {} );

            })
        );
    }

    
    async users(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers/'+inventoryFeedProviderId+'/users', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users",e);
                return( e );
            })
        );
    }


    
    async autoSetIconForFeedRunnerUsers(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.put( '/inventory-feed-providers/'+inventoryFeedProviderId+'/auto-set-icon-for-feed-runner-users', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users",e);
                return( e );
            })
        );
    }


    
    
    async addComment(inventoryFeedProviderId,commentText) {

        return( 
            this.lucore.axios.post( '/inventory-feed-providers/'+inventoryFeedProviderId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }


    /**
     * 
     * @param {name,description} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/inventory-feed-providers', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create inventory-feed-provider",e);
                return( e );

            })
        );
    }

    
    /**
     * Update the inventory feed provider
     * * @param {name,description} params 
     */
    async update(inventoryFeedProviderId,params) {

        return( 
            this.lucore.axios.put( '/inventory-feed-providers/'+inventoryFeedProviderId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeedProviders update",e);
                return( e );

            })
        );
    }

    
    
    /**
     * Add an Inventory Item Class
     * 
     */
     async addInventoryItemClass(inventoryFeedProviderId,inventoryItemClass) {

        const params = {
            inventory_item_class: inventoryItemClass
        }

        return( 
            this.lucore.axios.post( '/inventory-feed-providers/'+inventoryFeedProviderId+'/inventory-item-class', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in account addInventoryItemClass",e);
                return( {} );

            })
        );
    }

    
    /**
     * Add an Inventory Item Class
     * 
     */
    async removeInventoryItemClass(inventoryFeedProviderId,inventoryItemClass) {

        const params = {
            inventory_item_class: inventoryItemClass
        }

        return( 
            this.lucore.axios.put( '/inventory-feed-providers/'+inventoryFeedProviderId+'/inventory-item-class', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in account addInventoryItemClass",e);
                return( {} );

            })
        );
    }



    
    /**
     * 
     * @param {inventoryFeedProviderId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(inventoryFeedProviderId,file) {
        return( 
            this.lucore.setPrimaryImage('inventory-feed-providers',inventoryFeedProviderId,file,"Profile")
        )
    }



    
    /**
     * Update the inventory feed provider active state
     * 
     */
    async setActive(inventoryFeedProviderId,active) {

        const params = {
            active: active
        }

        return( 
            this.lucore.axios.put( '/inventory-feed-providers/'+inventoryFeedProviderId+'/setActive', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeed setActive",e);
                return( e );

            })
        );
    }


    
    async history(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers/'+inventoryFeedProviderId+'/history', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in history",e);
                return( {} );
            })
        );
    }

    

    
    
    async tasks(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers/'+inventoryFeedProviderId+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }

    
    /**
     * 
     * @param {*} inventoryFeedProviderId 
     * @param {title,description,due_at,class} taskParams 
     */
    async addTask(inventoryFeedProviderId,taskParams) {

        return( 
            this.lucore.axios.post( '/inventory-feed-providers/'+inventoryFeedProviderId+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }

    async stories(id,page) {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }

    
    async following(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.get( '/inventory-feed-providers/'+inventoryFeedProviderId+'/following', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in following",e);
                return( {} );
            })
        );
    }

    
    
    async follow(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.post( '/inventory-feed-providers/'+inventoryFeedProviderId+'/following', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in follow",e);
                return( {} );
            })
        );
    }

    
    
    async removeFollowing(inventoryFeedProviderId) {

        return( 
            this.lucore.axios.delete( '/inventory-feed-providers/'+inventoryFeedProviderId+'/following' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeFollowing",e);
                return( {} );
            })
        );
    }

    

    
    
}

export default LuCoreServiceInventoryFeedProviders;