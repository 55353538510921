class LuCoreServiceDigitalBoards {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list( params ) {

        return( 
            this.lucore.axios.get( '/digital-boards', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in digital-boards",e);
                return( e );

            })
        );
    }

    async getMap(params) {

        return( 
            this.lucore.axios.get( '/digital-boards/map', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in boardTypes",e);
                return( e );
            })
        );
    }

    
    async get(digitalBoardId, cacheable) {

        const cacheKey = 'LuCoreServiceDigitalBoards_get_'+digitalBoardId;
        
        return( 

            this.lucore.cacheable( cacheKey, cacheable ).then( cachedResponse => {

                if( cachedResponse )
                    return( cachedResponse );


                return( 
                    this.lucore.axios.get( '/digital-boards/'+digitalBoardId, {})
                    .then(response => {
                        this.lucore.storeCache( cacheKey, response.data );
                        return( response.data );
                    })
                    .catch(e => {
                        this.lucore.logError("Error in digital-boards get",e);
                        return( e );
        
                    })
                );
                
            })

        )
    }
    
    async boardTypes() {

        return( 
            this.lucore.axios.get( '/digital-boards/board-types', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in boardTypes",e);
                return( e );
            })
        );
    }
    
    async hardwareProviders() {

        return( 
            this.lucore.axios.get( '/digital-boards/hardware-providers', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in hardwareProviders",e);
                return( e );
            })
        );
    }
    
    async softwareProviders() {

        return( 
            this.lucore.axios.get( '/digital-boards/software-providers', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in softwareProviders",e);
                return( e );
            })
        );
    }

    
    async impressionCalcMethods() {

        return( 
            this.lucore.axios.get( '/digital-boards/impressions-calc-methods', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in impressionCalcMethods",e);
                return( e );
            })
        );
    }

    
    async facingCardinals() {

        return( 
            this.lucore.axios.get( '/digital-boards/facing-cardinals', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in impressionCalcMethods",e);
                return( e );
            })
        );
    }

    
    async venueTaxonomyIds() {

        return(
            this.lucore.axios.get( '/digital-boards/venue-taxonomy-ids', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in venueTaxonomyIds",e);
                return( e );
            })
        );
    }

    //digital-board-formats
    async digitalBoardFormats() {

        return(
            this.lucore.axios.get( '/digital-boards/digital-board-formats', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in digitalBoardFormats",e);
                return( e );
            })
        );
    }

    //transact-channel-classes
    async transactChannelClasses() {

        return(
            this.lucore.axios.get( '/digital-boards/transact-channel-classes', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in transactChannelClasses",e);
                return( e );
            })
        );
    }

    //media-type-classes
    async mediaTypeClasses() {

        return(
            this.lucore.axios.get( '/digital-boards/media-type-classes', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in mediaTypeClasses",e);
                return( e );
            })
        );
    }


    async stories(id,page) {

        return( 
            this.lucore.axios.get( '/digital-boards/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }




    
    async comments(digitalBoardId) {

        return( 
            this.lucore.axios.get( '/digital-boards/'+digitalBoardId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( e );
            })
        );
    }

    
    
    async addComment(digitalBoardId,commentText) {

        return( 
            this.lucore.axios.post( '/digital-boards/'+digitalBoardId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( e );
            })
        );
    }


    async setIsDemo(id) {

        return( 
            this.lucore.axios.put( '/digital-boards/'+id+'/is-demo', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setIsDemo",e);
                return( {} );
            })
        );
    }

    
    
    async removeIsDemo(id) {

        return( 
            this.lucore.axios.delete( '/digital-boards/'+id+'/is-demo' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeIsDemo",e);
                return( {} );
            })
        );
    }

    /**
     * 
     * @param {agency_id,name,board_identifier,board_type,network,width,height,hardware_provider,software_provider,location_id,city,region,country,latitude,longitude} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/digital-boards', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create digitalBoard",e);
                return( e );

            })
        );
    }
    
    /**
     * Update the digitalBoard
     * @param {digitalBoardId} digitalBoardId
     * @param {agency_id,name,board_identifier,board_type,network,width,height,hardware_provider,software_provider} params 
     */
    async update(digitalBoardId,params) {

        return( 
            this.lucore.axios.put( '/digital-boards/'+digitalBoardId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in digitalBoard update",e);
                return( e );

            })
        );
    }

    
    /**
     * 
     * @param {digitalBoardId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(digitalBoardId,file) {
        return( 
            this.lucore.setPrimaryImage('digital-boards',digitalBoardId,file,'DigitalBillboardStockImageResizer')
        )
    }

    
    /**
     * Update the digital board status
     * 
     */
    async setStatus(digitalBoardId,status) {

        const params = {
            status: status
        }

        return( 
            this.lucore.axios.put( '/digital-boards/'+digitalBoardId+'/setStatus', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setStatus",e);
                return( e );

            })
        );
    }


    
    /**
     * 
     * 
     */
     async setStatsDelayed(digitalBoardId,stats_delayed) {

        const params = {
            stats_delayed: stats_delayed ? 1 : 0
        }

        return( 
            this.lucore.axios.put( '/digital-boards/'+digitalBoardId+'/set-stats-delayed', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setStatsDelayed",e);
                return( e );

            })
        );
    }

    
    /**
     * 
     * 
     */
     async setStatsUnavailable(digitalBoardId,stats_unavailable) {

        const params = {
            stats_unavailable: stats_unavailable ? 1 : 0
        }

        return( 
            this.lucore.axios.put( '/digital-boards/'+digitalBoardId+'/set-stats-unavailable', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setStatsDelayed",e);
                return( e );

            })
        );
    }

    async setStreetViewStatus(digitalBoardId,street_view_status,street_view_review_status) {

        const params = {
            street_view_status: street_view_status,
            street_view_review_status: street_view_review_status
        }

        return(
            this.lucore.axios.put( '/digital-boards/'+digitalBoardId+'/street-view-location/set-status', params
            )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setStreetViewStatus",e);
                return( e );

            })
        );
    }



    
    async boardImages( digitalBoardId, params ) {

        return( 
            this.lucore.axios.get( '/digital-boards/'+digitalBoardId+'/board-images', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in boardImages",e);
                return( e );

            })
        );
    }
    
    
    async showBoardImage( digitalBoardToImageId ) {

        return( 
            this.lucore.axios.get( '/digital-boards/board-images/'+digitalBoardToImageId)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in boardImages",e);
                return( e );

            })
        );
    }


    

    
    async addBoardImage( digitalBoardId, params ) {

        return( 
            this.lucore.axios.post( '/digital-boards/'+digitalBoardId+'/board-images', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addBoardImage",e);
                return( e );

            })
        );
    }

    
    async updateBoardImage( digitalBoardId, digitalBoardToImageId, params ) {

        return( 
            this.lucore.axios.put( '/digital-boards/'+digitalBoardId+'/board-images/'+digitalBoardToImageId, params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in updateBoardImage",e);
                return( e );

            })
        );
    }

    
    async deleteBoardImage( digitalBoardId, digitalBoardToImageId ) {

        return( 
            this.lucore.axios.delete( '/digital-boards/'+digitalBoardId+'/board-images/'+digitalBoardToImageId)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in deleteBoardImage",e);
                return( e );

            })
        );
    }



    async rebuildDigitalBoardCreatives( digitalBoardId, params ) {

        return( 
            this.lucore.axios.put( '/digital-boards/'+digitalBoardId+'/rebuild-digital-board-creatives', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in updateBoardImage",e);
                return( e );

            })
        );
    }
    
    
    async boardImageCreatives( digitalBoardId, params ) {

        return( 
            this.lucore.axios.get( '/digital-boards/'+digitalBoardId+'/board-image-creatives', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in boardImages",e);
                return( e );

            })
        );
    }



}

export default LuCoreServiceDigitalBoards;
