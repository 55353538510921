import React, { useState } from 'react'
import LocalStorageService from '../services/storage/LocalStorageService';


export const UserContext = React.createContext({
  data: {
    loaded: false,
    currentAccountId : null
  },
  isLoggedIn: false,
  setData: () => {},
  setIsLoggedIn: () => {},
  setCurrentAccountId: () => {},
})

export const UserContextProvider = (props)  => {

  const setData = (data) => {

    const lc = new LocalStorageService();
    
    if( data.currentAccountId )
      lc.set("user_state_current_account_id",data.currentAccountId)
    
    setState({...state, data: data})
  }

  const setIsLoggedIn = (isLoggedIn) => {
    setState({...state, isLoggedIn: isLoggedIn})
  }


  const initState = {
    name: {
      loaded: false,
      currentAccountId: null,    
    },
    isLoggedIn: false,
    
    setData: setData,
    setIsLoggedIn: setIsLoggedIn
  } 

  const [state, setState] = useState(initState)

  return (
    <UserContext.Provider value={state}>
      {props.children}
    </UserContext.Provider>
  )
}


