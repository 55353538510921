class LuCoreServiceInventoryExports {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list(params) {

        return( 
            this.lucore.axios.get( '/inventory-exports', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports list",e);
                return( {} );

            })
        );
    }

    async get(inventoryExportId) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports get",e);
                return( {} );

            })
        );
    }   

    

    async checkForMatchingFilenames(inventoryExportId) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId+'/check-for-matching-filenames', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports get",e);
                return( {} );

            })
        );
    }   

    

    async autoSetPrimaryImage(inventoryExportId) {

        return( 
            this.lucore.axios.put( '/inventory-exports/'+inventoryExportId+'/auto-set-primary-image', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in autoSetPrimaryImage get",e);
                return( {} );

            })
        );
    }  


    


    
    async getRun(exportRunId) {

        return( 
            this.lucore.axios.get( '/inventory-exports/run/'+exportRunId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports getRun",e);
                return( {} );

            })
        );
    }   

    
    async getRunPusherCacheFile(exportRunId) {

        return( 
            this.lucore.axios.get( '/inventory-exports/run/'+exportRunId+'/cacheFile', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports getRunPusherCacheFile",e);
                return( {} );

            })
        );
    }   

    
    async getRunLogs(exportRunId) {

        return( 
            this.lucore.axios.get( '/inventory-exports/run/'+exportRunId+'/logs', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports getRunLogs",e);
                return( e );

            })
        );
    }   

    
    async runs(inventoryExportId, params) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId+'/runs', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports runs",e);
                return( {} );

            })
        );
    }   

    
    
    async campaignStateHistory(inventoryExportId, params) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId+'/campaign-state-history', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports campaignStateHistory",e);
                return( {} );

            })
        );
    }   

    
    
    async getCampaignStateHistory(campaignStateHistoryId) {

        return( 
            this.lucore.axios.get( '/inventory-exports/campaign-state-history/'+campaignStateHistoryId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports getRun",e);
                return( {} );

            })
        );
    }   



    
    
    async recentChangedRuns() {

        const params = {
            "changed_only" : 1
        }

        return( 
            this.lucore.axios.get( '/inventory-exports/runs', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports recentChangedRuns",e);
                return( {} );

            })
        );
    }   

    
    
    async recentChangedOrNotCompleteRuns() {

        const params = {
            "changed_or_not_complete" : 1
        }

        return( 
            this.lucore.axios.get( '/inventory-exports/runs', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports recentChangedOrNotCompleteRuns",e);
                return( {} );

            })
        );
    }   

    async testFilter(inventoryExportId, params) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId+'/test-filter', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports testFilter",e);
                return( {} );

            })
        );
    }   
    
    async testTransformer(inventoryExportId, params) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId+'/test-transformer', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExports testTransformer",e);
                return( {} );

            })
        );
    }   


    
    async billboardPlayStats(inventoryExportId, params ) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId+'/billboards/play-stats', { params : params} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in playStats",e);
                return( {} );
            })
        );
    }

    
    async comments(inventoryExportId) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(inventoryExportId,commentText) {

        return( 
            this.lucore.axios.post( '/inventory-exports/'+inventoryExportId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }

    

    async setIsDemo(id) {

        return( 
            this.lucore.axios.put( '/inventory-exports/'+id+'/is-demo', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setIsDemo",e);
                return( {} );
            })
        );
    }

    
    
    async removeIsDemo(id) {

        return( 
            this.lucore.axios.delete( '/inventory-exports/'+id+'/is-demo' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeIsDemo",e);
                return( {} );
            })
        );
    }


    async addApxContract(inventoryExportId,apxContractId) {

        return( 
            this.lucore.axios.put( '/inventory-exports/'+inventoryExportId+'/apx-contract/'+apxContractId )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addApxContract",e);
                return( {} );
            })
        );
    }

    async deleteApxContract(inventoryExportId,apxContractId) {

        return( 
            this.lucore.axios.delete( '/inventory-exports/'+inventoryExportId+'/apx-contract/'+apxContractId )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in deleteApxContract",e);
                return( {} );
            })
        );
    }



    async addPxDealMap(inventoryExportId,pxDealId) {

        return(
            this.lucore.axios.put( '/inventory-exports/'+inventoryExportId+'/px-deal-map/'+pxDealId )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addPxDealMap",e);
                return( {} );
            })
        );
    }

    async deletePxDealMap(inventoryExportId,pxDealId) {

        return(
            this.lucore.axios.delete( '/inventory-exports/'+inventoryExportId+'/px-deal-map/'+pxDealId )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in deletePxDealMap",e);
                return( {} );
            })
        );
    }



    
    /**
     * Update the inventory export
     * * @param {name,account_id} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/inventory-exports', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExportcreate",e);
                return( e );

            })
        );
    }


    
    /**
     * Update the inventory export
     * * @param {name,account_id,schedule,export_settings} params 
     */
    async update(inventoryExportId,params) {

        return( 
            this.lucore.axios.put( '/inventory-exports/'+inventoryExportId+'/admin-update', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExport update",e);
                return( e );

            })
        );
    }

    
    
    /**
     * 
     * @param {inventoryExportId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(inventoryExportId,file) {
        return( 
            this.lucore.setPrimaryImage('inventory-exports',inventoryExportId,file,"Profile")
        )
    }



    /**
     * Update the inventory export active state
     * 
     */
    async setActive(inventoryExportId,active) {

        const params = {
            active: active
        }

        return( 
            this.lucore.axios.put( '/inventory-exports/'+inventoryExportId+'/setActive', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExport update",e);
                return( {} );

            })
        );
    }

    

    /**
     * Make runnable and queue it
     * 
     */
    async makeRunnable(inventoryExportId) {

        return( 
            this.lucore.axios.put( '/inventory-exports/'+inventoryExportId+'/make-runnable', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExport makeRunnable",e);
                return( {} );

            })
        );
    }


    
    
    /**
     * Copy this export to another account 
     * 
     */
     async copyToAccount(inventoryExportId,accountId) {

        return( 
            this.lucore.axios.post( '/inventory-exports/'+inventoryExportId+'/copy-to-account/'+accountId, {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExport copyToAccount",e);
                return( {} );

            })
        );
    }

    
    
    /**
     * Copy this export to another export 
     * 
     */
     async copyToExport(inventoryExportId,destExportId) {

        return( 
            this.lucore.axios.post( '/inventory-exports/'+inventoryExportId+'/copy-to-export/'+destExportId, {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExport copyToExport",e);
                return( {} );

            })
        );
    }




    async stories(id,page) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }




    
    async following(inventoryExportId) {

        return( 
            this.lucore.axios.get( '/inventory-exports/'+inventoryExportId+'/following', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in following",e);
                return( {} );
            })
        );
    }

    
    
    async follow(inventoryExportId) {

        return( 
            this.lucore.axios.post( '/inventory-exports/'+inventoryExportId+'/following', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in follow",e);
                return( {} );
            })
        );
    }

    
    
    async removeFollowing(inventoryExportId) {

        return( 
            this.lucore.axios.delete( '/inventory-exports/'+inventoryExportId+'/following' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeFollowing",e);
                return( {} );
            })
        );
    }

    
    /**
     * Update the inventory export setStatus
     * 
     */
    async setStatus(inventoryExportId,status) {

        const params = {
            status: status
        }

        return( 
            this.lucore.axios.put( '/inventory-exports/'+inventoryExportId+'/setStatus', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryExport setStatus",e);
                return( e );

            })
        );
    }

    async apxContractMaps(params) {
        
        return (
            this.lucore.axios.get('/campaigns/apx-contract-maps', { params: params })
                .then(response => {
                    return (response.data);
                })
                .catch(e => {
                    this.lucore.logError("Error in apxContractMaps", e);
                    return (e);
                })
        )
    }

    async pxDealMaps(params) {

        return (
            this.lucore.axios.get('/campaigns/px-deal-maps', { params: params })
                .then(response => {
                    return (response.data);
                })
                .catch(e => {
                    this.lucore.logError("Error in pxDealMaps", e);
                    return (e);
                })
        )
    }



}

export default LuCoreServiceInventoryExports;
