class LuCoreServiceAnalytics {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    /**
     * 
     * @param {model,interface,filterParams} params 
     */
    async fetch( params ) {

        const model = params.model;
        const modelInterface = params.interface;
        const filterParams = params.filterParams;

        return( 
            this.lucore.axios.get( '/analytics/fetch/'+model+'/'+modelInterface, { params : filterParams} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analytics fetch",e);
                return( e );

            })
        );
    }

    /**
     * 
     * @param {account_id,timeframe,period} params 
     */
    async playsByPeriod( params ) {

        return( 
            this.lucore.axios.get( '/analytics/fetch/plays/by-period', { params : params} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analytics playsByPeriod",e);
                return( e );

            })
        );
    }



    /**
     * 
     * 
     */
    async datePeriods() {       

        return( 
            this.lucore.axios.get( '/analytics/filters/date-periods', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analytics datePeriods",e);
                return( e );

            })
        );
    }


    /**
     * 
     * 
     */
    async datePeriodsAll() {       

        return( 
            this.lucore.axios.get( '/analytics/filters/date-periods-all', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analytics datePeriodsAll",e);
                return( e );

            })
        );
    }

    /**
     * 
     * 
     */
    async dateTimeframes() {       

        return( 
            this.lucore.axios.get( '/analytics/filters/date-timeframes', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analytics timeframes",e);
                return( e );

            })
        );
    }

    /**
     * 
     * 
     */
    async dateTimeframesAll() {       

        return( 
            this.lucore.axios.get( '/analytics/filters/date-timeframes-all', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analytics dateTimeframesAll",e);
                return( e );

            })
        );
    }


    async blipMinerBatchesList( params ) {
            
            return( 
                this.lucore.axios.get( '/lac/blipminer/list-batches', { params : params} )
                .then(response => {
                    return( response.data );
                })
                .catch(e => {
                    this.lucore.logError("Error in blipMinerBatchesList",e);
                    return( e );
    
                })
            );
    }

    ///lac/blipminer/{id}
    async blipMinerBatch( id ) {
            
            return( 
                this.lucore.axios.get( '/lac/blipminer/'+id, {} )
                .then(response => {
                    return( response.data );
                })
                .catch(e => {
                    this.lucore.logError("Error in blipMinerBatch",e);
                    return( e );
    
                })
            );
    }

    //lac/blipminer/{id}/logs
    async blipMinerBatchLogs( id ) {
        
        return( 
            this.lucore.axios.get( '/lac/blipminer/'+id+'/logs', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in blipMinerBatchLogs",e);
                return( e );

            })
        );
    }   



    async igniteBotBatchesList( params ) {
                
        return( 
            this.lucore.axios.get( '/lac/ignitebot/list-batches', { params : params} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in igniteBotBatchesList",e);
                return( e );

            })
        );
    }

    ///lac/ignitebot/{id}
    async igniteBotBatch( id ) {
        
        return( 
            this.lucore.axios.get( '/lac/ignitebot/'+id, {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in igniteBotBatch",e);
                return( e );

            })
        );
    }

    //lac/ignitebot/{id}/logs
    async igniteBotBatchLogs( id ) {

    return( 
        this.lucore.axios.get( '/lac/ignitebot/'+id+'/logs', {} )
        .then(response => {
            return( response.data );
        })
        .catch(e => {
            this.lucore.logError("Error in igniteBotBatchLogs",e);
            return( e );

        })
    );
    }   


    
    /**
     * /lac/liops/list-message-logs
     */
    async liopsMessageLogList( params ) {
                
        return( 
            this.lucore.axios.get( '/lac/liops/list-message-logs', { params : params} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in liopsMessageLogList",e);
                return( e );

            })
        );
    }

    ///lac/liops/{id}
    async liopsShowMessageLog( id ) {
        
        return( 
            this.lucore.axios.get( '/lac/liops/'+id, {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in liopsShowMessageLog",e);
                return( e );

            })
        );
    }

    //lac/liops/{id}/logs
    async liopsGetMessageLogLogs( id ) {

    return( 
        this.lucore.axios.get( '/lac/liops/'+id+'/logs', {} )
        .then(response => {
            return( response.data );
        })
        .catch(e => {
            this.lucore.logError("Error in getMessageLogLogs",e);
            return( e );

        })
    );
    }   


    
    
    /**
     * /lac/analytics/status
     */
    async analyticsStatus( params ) {
                
        return( 
            this.lucore.axios.get( '/lac/analytics/status', { params : params} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analyticsStatus",e);
                return( e );

            })
        );
    }

    /**
     * /lac/analytics/batches
     */
    async analyticsBatches( params ) {
                
        return( 
            this.lucore.axios.get( '/lac/analytics/batches', { params : params} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analyticsBatches",e);
                return( e );

            })
        );
    }

    /**
     * /lac/analytics/batches/{id}
     */
    async analyticsBatch( id ) {

        return( 
            this.lucore.axios.get( '/lac/analytics/batches/'+id, {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analyticsBatch",e);
                return( e );

            })
        );
    }

    /**
     * /lac/analytics/batches/{id}/logs
     *
     * @param {*} id
     * 
     */
    async analyticsBatchLogs( id ) {
        
        return( 
            this.lucore.axios.get( '/lac/analytics/batches/'+id+'/logs', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in analyticsBatchLogs",e);
                return( e );

            })
        );
    } 
    
        /**
         * /lac/analytics/batches/{id}/batch-records
         */
        async analyticsBatchRecords( id ) {
            
            return( 
                this.lucore.axios.get( '/lac/analytics/batches/'+id+'/batch-records', {} )
                .then(response => {
                    return( response.data );
                })
                .catch(e => {
                    this.lucore.logError("Error in analyticsBatchRecords",e);
                    return( e );
    
                })
            );
        }
        


}

export default LuCoreServiceAnalytics;