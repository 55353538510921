


window.testAccountView = function()
{
    window.location='#/accounts/39';
}
window.testFeedRunView = function()
{
    window.location='#/inventory-feed-run/75';
}