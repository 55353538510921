class LuCoreServiceReports {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }


    async list(params) {
        return(
            this.lucore.axios.get( '/lac/reports', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in list",e);
                return( {} );

            })
        );
    }

    async get(reportLcuid) {
        return(
            this.lucore.axios.get( '/lac/reports/'+reportLcuid )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in get",e);
                return( {} );

            })
        );
    }

    async create(report) {
        return(
            this.lucore.axios.post( '/lac/reports', report )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in store",e);
                return( {} );

            })
        );
    }

    async update(reportLcuid, report) {
        return(
            this.lucore.axios.put( '/lac/reports/'+reportLcuid, report )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in update",e);
                return( {} );

            })
        );
    }

    async delete(reportLcuid) {
        return(
            this.lucore.axios.delete( '/lac/reports/'+reportLcuid )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in delete",e);
                return( {} );

            })
        );
    }

    async run(reportLcuid,reportFields) {
        return(
            this.lucore.axios.post( '/lac/reports/'+reportLcuid+'/run', {report_fields : reportFields } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in run",e);
                return( {} );

            })
        );
    }


}

export default LuCoreServiceReports;
