class LuCoreServiceSupport {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async environment() {

        return( 
            this.lucore.axios.get( '/environment', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in environment",e);
                return( e );

            })
        );
    }
    


}

export default LuCoreServiceSupport;