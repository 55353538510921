class LuCoreServiceQueue {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    
    async scheduledTasks() {

        return(
            this.lucore.axios.get( '/lac/queue/scheduled-tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceQueue scheduled-tasks",e);
                return( e );

            })
        );
    }



    async stats() {

        return( 
            this.lucore.axios.get( '/lac/queue/stats', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceQueue stats",e);
                return( e );

            })
        );
    }

    
    async jobs(params) {

        return( 
            this.lucore.axios.get( '/lac/queue/jobs',params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceQueue jobs",e);
                return( e );

            })
        );
    }

    async failed(params) {

        return( 
            this.lucore.axios.get( '/lac/queue/jobs/failed',params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceQueue failed",e);
                return( e );

            })
        );
    }

    async flush() {
            
            return( 
                this.lucore.axios.put( '/lac/queue/jobs/failed/flush',{})
                .then(response => {
                    return( response.data );
                })
                .catch(e => {
                    this.lucore.logError("Error in LuCoreServiceQueue flush",e);
                    return( e );
    
                })
            );
    }

    async retryAll() {

        return( 
            this.lucore.axios.put( '/lac/queue/jobs/failed/retry-all',{})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceQueue retryAll",e);
                return( e );

            })
        );
    }

    
    async retry(id) {

        return( 
            this.lucore.axios.put( '/lac/queue/jobs/failed/'+id+'/retry',{})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in LuCoreServiceQueue retry",e);
                return( e );

            })
        );
    }
}

export default LuCoreServiceQueue;
