class LuCoreServiceInvoices {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async lineItemClasses() {

        return(
            this.lucore.axios.get( '/invoices/line-item-classes', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in lineItemClasses",e);
                return( e );

            })
        );
    }

    async list( params ) {

        return(
            this.lucore.axios.get( '/invoices', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoices",e);
                return( e );

            })
        );
    }

    async get(invoiceId) {

        return(
            this.lucore.axios.get( '/invoices/'+invoiceId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoices get",e);
                return( e );

            })
        );
    }

    async pay(invoiceId) {

        return(
            this.lucore.axios.put( '/invoices/'+invoiceId+'/pay', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoices pay",e);
                return( e );

            })
        );
    }


    async addLineItem(invoiceId,params) {

        return(
            this.lucore.axios.post( '/invoices/'+invoiceId+'/line-items',params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addLineItem",e);
                return( e );
            })
        );
    }



    async comments(invoiceId) {

        return(
            this.lucore.axios.get( '/invoices/'+invoiceId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( e );
            })
        );
    }



    async addComment(invoiceId,commentText) {

        return(
            this.lucore.axios.post( '/invoices/'+invoiceId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( e );
            })
        );
    }

    async create(params) {

        return(
            this.lucore.axios.post( '/invoices', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create invoice",e);
                return( e );

            })
        );
    }


    async update(invoiceId,params) {

        return(
            this.lucore.axios.put( '/invoices/'+invoiceId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoice update",e);
                return( e );

            })
        );
    }




    async stories(id,page) {

        return(
            this.lucore.axios.get( '/invoices/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }


    async tasks(invoiceId) {

        return(
            this.lucore.axios.get( '/invoices/'+invoiceId+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }


    async addTask(invoiceId,taskParams) {

        return(
            this.lucore.axios.post( '/invoices/'+invoiceId+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }




}

export default LuCoreServiceInvoices;
