class LuCoreServiceSearch {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    /**
     * Acceptable params
     *   page (the page number)
     *   per_page (the number per page)
     *   query (a search query)
     */
    async list( params ) {

        return( 
            this.lucore.axios.get( '/search', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in search",e);
                return( {} );

            })
        );
    }

}

export default LuCoreServiceSearch;