class LuCoreServiceInventoryFeeds {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list(params) {

        return( 
            this.lucore.axios.get( '/inventory-feeds', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds list",e);
                return( {} );

            })
        );
    }

    async get(inventoryFeedId) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/'+inventoryFeedId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds get",e);
                return( {} );

            })
        );
    }   



    async runModule(inventoryFeedId,feedModule) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/'+inventoryFeedId+'/run'+feedModule, {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds runModule",e);
                return( e );

            })
        );
    }   
    
    async getRun(feedRunId) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/run/'+feedRunId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds getRun",e);
                return( {} );

            })
        );
    }   

    
    async getCacheFile(feedRunId,cacheFileType) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/run/'+feedRunId+'/cacheFile', { params : {cache_file_type:cacheFileType}} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds getCacheFile",e);
                return( {} );

            })
        );
    }   


    async getRunLogs(feedRunId) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/run/'+feedRunId+'/logs', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds getRunLogs",e);
                return( e );

            })
        );
    }   
    
    async runs(inventoryFeedId, params) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/'+inventoryFeedId+'/runs', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds runs",e);
                return( {} );

            })
        );
    }   

    
    
    async recentChangedRuns() {

        const params = {
            "changed_only" : 1
        }

        return( 
            this.lucore.axios.get( '/inventory-feeds/runs', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds recentChangedRuns",e);
                return( {} );

            })
        );
    }   

    
    
    async recentChangedOrNotCompleteRuns() {

        const params = {
            "changed_or_not_complete" : 1
        }

        return( 
            this.lucore.axios.get( '/inventory-feeds/runs', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeeds recentChangedOrNotCompleteRuns",e);
                return( {} );

            })
        );
    }   

    
    
    async comments(inventoryFeedId) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/'+inventoryFeedId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(inventoryFeedId,commentText) {

        return( 
            this.lucore.axios.post( '/inventory-feeds/'+inventoryFeedId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }


    

    async setIsDemo(id) {

        return( 
            this.lucore.axios.put( '/inventory-feeds/'+id+'/is-demo', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setIsDemo",e);
                return( {} );
            })
        );
    }

    
    
    async removeIsDemo(id) {

        return( 
            this.lucore.axios.delete( '/inventory-feeds/'+id+'/is-demo' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeIsDemo",e);
                return( {} );
            })
        );
    }


    
    async history(inventoryFeedId) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/'+inventoryFeedId+'/history', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in history",e);
                return( {} );
            })
        );
    }

    

    
    
    async tasks(inventoryFeedId) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/'+inventoryFeedId+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }

    
    /**
     * 
     * @param {*} inventoryFeedId 
     * @param {title,description,due_at,class} taskParams 
     */
    async addTask(inventoryFeedId,taskParams) {

        return( 
            this.lucore.axios.post( '/inventory-feeds/'+inventoryFeedId+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }


    async stories(id,page) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }



    
    async following(inventoryFeedId) {

        return( 
            this.lucore.axios.get( '/inventory-feeds/'+inventoryFeedId+'/following', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in following",e);
                return( {} );
            })
        );
    }

    
    
    async follow(inventoryFeedId) {

        return( 
            this.lucore.axios.post( '/inventory-feeds/'+inventoryFeedId+'/following', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in follow",e);
                return( {} );
            })
        );
    }

    
    
    async removeFollowing(inventoryFeedId) {

        return( 
            this.lucore.axios.delete( '/inventory-feeds/'+inventoryFeedId+'/following' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeFollowing",e);
                return( {} );
            })
        );
    }

    


    
    /**
     * Update the inventory feed
     * * @param {name,account_id,inventory_feed_provider_id} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/inventory-feeds', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeed create",e);
                return( e );

            })
        );
    }


    
    /**
     * Update the inventory feed
     * * @param {name,account_id,inventory_feed_provider_id,schedule,feed_settings} params 
     */
    async update(inventoryFeedId,params) {

        return( 
            this.lucore.axios.put( '/inventory-feeds/'+inventoryFeedId+'/update-admin', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeed update",e);
                return( e );

            })
        );
    }

    
    /**
     * 
     * @param {inventoryFeedId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(inventoryFeedId,file) {
        return( 
            this.lucore.setPrimaryImage('inventory-feeds',inventoryFeedId,file)
        )
    }


    /**
     * Update the inventory feed active state
     * 
     */
    async setActive(inventoryFeedId,active) {

        const params = {
            active: active
        }

        return( 
            this.lucore.axios.put( '/inventory-feeds/'+inventoryFeedId+'/setActive', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeed setActive",e);
                return( e );

            })
        );
    }

    /**
     * Make runnable and queue it
     * 
     */
    async makeRunnable(inventoryFeedId) {

        return( 
            this.lucore.axios.put( '/inventory-feeds/'+inventoryFeedId+'/make-runnable', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeed makeRunnable",e);
                return( {} );

            })
        );
    }


    
    /**
     * Copy this feed to another account 
     * 
     */
     async copyToAccount(inventoryFeedId,accountId) {

        return( 
            this.lucore.axios.post( '/inventory-feeds/'+inventoryFeedId+'/copy-to-account/'+accountId, {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeed copyToAccount",e);
                return( {} );

            })
        );
    }

    
    
    /**
     * Copy this feed to another feed 
     * 
     */
     async copyToFeed(inventoryFeedId,destFeedId) {

        return( 
            this.lucore.axios.post( '/inventory-feeds/'+inventoryFeedId+'/copy-to-feed/'+destFeedId, {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeed copyToFeed",e);
                return( {} );

            })
        );
    }



    
    /**
     * Update the inventory feed setStatus
     * 
     */
    async setStatus(inventoryFeedId,status) {

        const params = {
            status: status
        }

        return( 
            this.lucore.axios.put( '/inventory-feeds/'+inventoryFeedId+'/setStatus', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryFeed setStatus",e);
                return( e );

            })
        );
    }



}

export default LuCoreServiceInventoryFeeds;