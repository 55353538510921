class LuCoreServicePayments {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async paymentSourceClasses() {

            return(
                this.lucore.axios.get( '/payments/payment-source-classes', {} )
                .then(response => {
                    return( response.data );
                })
                .catch(e => {
                    this.lucore.logError("Error in paymentSourceClasses",e);
                    return( e );
                })
            );
    }


    async list( params ) {

        return(
            this.lucore.axios.get( '/payments', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in payments",e);
                return( e );

            })
        );
    }

    async get(paymentId) {

        return(
            this.lucore.axios.get( '/payments/'+paymentId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in payments get",e);
                return( e );

            })
        );
    }


    async comments(paymentId) {

        return(
            this.lucore.axios.get( '/payments/'+paymentId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( e );
            })
        );
    }



    async addComment(paymentId,commentText) {

        return(
            this.lucore.axios.post( '/payments/'+paymentId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( e );
            })
        );
    }

    async create(params) {

        return(
            this.lucore.axios.post( '/payments', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create payment",e);
                return( e );

            })
        );
    }


    async update(paymentId,params) {

        return(
            this.lucore.axios.put( '/payments/'+paymentId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in payment update",e);
                return( e );

            })
        );
    }


    async stories(id,page) {

        return(
            this.lucore.axios.get( '/payments/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }


    async tasks(id) {

        return(
            this.lucore.axios.get( '/payments/'+id+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }


    async addTask(id,taskParams) {

        return(
            this.lucore.axios.post( '/payments/'+id+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }


}

export default LuCoreServicePayments;
