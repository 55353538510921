class LuCoreServiceObjects {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async get(id) {

        return( 
            this.lucore.axios.get( '/objects/'+id, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in objects get",e);
                return( e );

            })
        );
        
    }

    async canBeDeleted(id) {

        return(
            this.lucore.axios.get( '/objects/'+id+'/can-be-deleted', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in objects canBeDeleted",e);
                return( e );
            })
        );
    }

    async delete(id) {
            
            return( 
                this.lucore.axios.delete( '/objects/'+id, {})
                .then(response => {
                    return( response.data );
                })
                .catch(e => {
                    this.lucore.logError("Error in objects delete",e);
                    return( e );
    
                })
            );
            
    }
    
    
    async comments(lcuid) {

        return( 
            this.lucore.axios.get( '/objects/'+lcuid+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(lcuid,commentText) {

        return( 
            this.lucore.axios.post( '/objects/'+lcuid+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }


    
    async history(lcuid) {

        return( 
            this.lucore.axios.get( '/objects/'+lcuid+'/history', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in history",e);
                return( {} );
            })
        );
    }




    async logs(lcuid) {

        return(
            this.lucore.axios.get( '/objects/'+lcuid+'/logs', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in logs",e);
                return( {} );
            })
        );
    }



    async objectTokens(lcuid) {

        return(
            this.lucore.axios.get( '/objects/'+lcuid+'/object-tokens', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in objectTokens",e);
                return( {} );
            })
        );
    }



}

export default LuCoreServiceObjects;
