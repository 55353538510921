class LuCoreServiceComments {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async modify(id,comment) {

        const params = {
            comment : comment
        }

        return( 
            this.lucore.axios.put( '/comments/'+id, params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments modify",e);
                return( e );

            })
        );
    }
    async delete(id) {

        return( 
            this.lucore.axios.delete( '/comments/'+id, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments delete",e);
                return( e );

            })
        );
    }



}

export default LuCoreServiceComments;