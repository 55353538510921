class LuCoreServiceInventoryPhotos {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    

    async get(inventoryPhotoId) {

        return( 
            this.lucore.axios.get( '/inventory-photos/'+inventoryPhotoId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in inventoryPhotos get",e);
                return( {} );

            })
        );
    }   

    
    async comments(inventoryPhotoId) {

        return( 
            this.lucore.axios.get( '/inventory-photos/'+inventoryPhotoId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    async addComment(inventoryPhotoId,commentText) {

        return( 
            this.lucore.axios.post( '/inventory-photos/'+inventoryPhotoId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }


    async removeBackground(inventoryPhotoId,queue) {

        return( 
            this.lucore.axios.post( '/inventory-photos/'+inventoryPhotoId+'/remove-background', { queue : queue} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeBackground",e);
                return( {} );
            })
        );
    }



}

export default LuCoreServiceInventoryPhotos;