import React from 'react';
import AppAuthorized from './AppAuthorized';
import AppNotAuthorized from './AppNotAuthorized';
import AuthProvider from './auth/AuthProvider';
import FatalException from './views/Exceptions/FatalException';
import { UserContext, UserContextProvider } from './context/UserContext';


const App = () => {

  
  const preFlightChecks = () => {
    if( !process.env.REACT_APP_LC_LUCORE_API_URL )
    {
      return("env is missing `REACT_APP_LC_LUCORE_API_URL` - Please check .env.local or .env.production.local");
    } 

    return( false );
  }

  /**
   * Let's perform some pre-flight checks for required things
   */
  const preFlightMessage = preFlightChecks();

  if( preFlightMessage )
  {
    return(
      <FatalException 
        message={preFlightMessage}
      />
    );
  }

  return(
    <UserContextProvider>
      <UserContext.Consumer>
        {(user) => (
          <AuthProvider user={user}
            authorizedComponent={<AppAuthorized/>} 
            notAuthorizedComponent={<AppNotAuthorized/>}
          />
        )}
      </UserContext.Consumer>
      
    </UserContextProvider>
  );

}

export default App;
