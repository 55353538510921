class LuCoreServiceTasks {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list( params ) {

        return( 
            this.lucore.axios.get( '/tasks', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( {} );

            })
        );
    }

    
    async my( params ) {

        return( 
            this.lucore.axios.get( '/tasks/my', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in my",e);
                return( {} );

            })
        );
    }

    
    async myDueAndUpcoming( params ) {

        return( 
            this.lucore.axios.get( '/tasks/my-due-and-upcoming', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in my-due-and-upcoming",e);
                return( {} );

            })
        );
    }

    
    async get(taskId, cacheable) {

        const cacheKey = 'LuCoreServiceTasks_get_'+taskId;
        
        return( 

            this.lucore.cacheable( cacheKey, cacheable ).then( cachedResponse => {

                if( cachedResponse )
                    return( cachedResponse );

                return(
                    
                    this.lucore.axios.get( '/tasks/'+taskId, {})
                    .then(response => {
                        this.lucore.storeCache( cacheKey, response.data );
                        return( response.data );
                    })
                    .catch(e => {
                        this.lucore.logError("Error in tasks get",e);
                        return( {} );
        
                    })    
                )
                
            })

        )
    }

    
    async comments(taskId) {

        return( 
            this.lucore.axios.get( '/tasks/'+taskId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(taskId,commentText) {

        return( 
            this.lucore.axios.post( '/tasks/'+taskId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }


    
    async reactions(taskId) {

        return( 
            this.lucore.axios.get( '/tasks/'+taskId+'/reactions', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in reactions",e);
                return( {} );
            })
        );
    }

    
    
    async addReaction(taskId,reactionClass) {

        return( 
            this.lucore.axios.post( '/tasks/'+taskId+'/reactions', { reaction_class : reactionClass} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addReaction",e);
                return( {} );
            })
        );
    }

    
    
    async removeReaction(taskId) {

        return( 
            this.lucore.axios.delete( '/tasks/'+taskId+'/reactions' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeReaction",e);
                return( {} );
            })
        );
    }

    
    

    
    async following(taskId) {

        return( 
            this.lucore.axios.get( '/tasks/'+taskId+'/following', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in following",e);
                return( {} );
            })
        );
    }

    
    
    async follow(taskId) {

        return( 
            this.lucore.axios.post( '/tasks/'+taskId+'/following', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in follow",e);
                return( {} );
            })
        );
    }

    
    
    async removeFollowing(taskId) {

        return( 
            this.lucore.axios.delete( '/tasks/'+taskId+'/following' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeFollowing",e);
                return( {} );
            })
        );
    }




    
    async history(taskId) {

        return( 
            this.lucore.axios.get( '/tasks/'+taskId+'/history', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in history",e);
                return( {} );
            })
        );
    }



    async tasks(taskId) {

        return( 
            this.lucore.axios.get( '/tasks/'+taskId+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }

    
    /**
     * 
     * @param {*} taskId 
     * @param {title,description,due_at,class} taskParams 
     */
    async addTask(taskId,taskParams) {

        return( 
            this.lucore.axios.post( '/tasks/'+taskId+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }

    
    
    /**
     * Update the agency
     * * @param {taskId} params 
     */
    async update(taskId,params) {

        return( 
            this.lucore.axios.put( '/tasks/'+taskId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in task update",e);
                return( e );

            })
        );
    }

    /**
     * Close a task
     * * @param {taskId} params 
     */
    async close(taskId) {

        const params = {
            closeStatus : 1
        }

        return( 
            this.lucore.axios.put( '/tasks/'+taskId+'/close', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in task close",e);
                return( e );

            })
        );
    }

    /**
     * reopen a task
     * * @param {taskId} params 
     */
    async reOpen(taskId) {

        const params = {
            closeStatus : 1
        }

        return( 
            this.lucore.axios.put( '/tasks/'+taskId+'/reOpen', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in task reOpen",e);
                return( e );

            })
        );
    }

    
    /**
     * reschedule a task
     * * @param {taskId} params 
     */
    async reSchedule(taskId,dueAt) {

        const params = {
            due_at : dueAt
        }

        return( 
            this.lucore.axios.put( '/tasks/'+taskId+'/reschedule', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in task reSchedule",e);
                return( e );

            })
        );
    }

    
    /**
     * reschedule a task
     * * @param {taskId} params 
     * * @param {userId} params 
     */
    async reassign(taskId,userId) {

        const params = {
            user_id : userId
        }

        return( 
            this.lucore.axios.put( '/tasks/'+taskId+'/reassign', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in task reassign",e);
                return( e );

            })
        );
    }




}

export default LuCoreServiceTasks;