class LuCoreServiceBillingPlanTemplates {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list( params ) {

        return(
            this.lucore.axios.get( '/billing-plan-templates', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlanTemplates",e);
                return( e );

            })
        );
    }

    async get(billingPlanTemplateId) {

        return(
            this.lucore.axios.get( '/billing-plan-templates/'+billingPlanTemplateId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlanTemplates get",e);
                return( e );

            })
        );
    }


    async billingPlans(billingPlanTemplateId) {

        return(
            this.lucore.axios.get( '/billing-plan-templates/'+billingPlanTemplateId+'/billing-plans', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlans",e);
                return( e );
            })
        );
    }


    async comments(billingPlanTemplateId) {

        return(
            this.lucore.axios.get( '/billing-plan-templates/'+billingPlanTemplateId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( e );
            })
        );
    }



    async addComment(billingPlanTemplateId,commentText) {

        return(
            this.lucore.axios.post( '/billing-plan-templates/'+billingPlanTemplateId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( e );
            })
        );
    }

    async create(params) {

        return(
            this.lucore.axios.post( '/billing-plan-templates', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create billingPlan",e);
                return( e );

            })
        );
    }


    async update(billingPlanTemplateId,params) {

        return(
            this.lucore.axios.put( '/billing-plan-templates/'+billingPlanTemplateId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlan update",e);
                return( e );

            })
        );
    }


    async run(billingPlanTemplateId) {

        return(
            this.lucore.axios.put( '/billing-plan-templates/'+billingPlanTemplateId+'/run', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlan update",e);
                return( e );

            })
        );
    }


    async stories(id,page) {

        return(
            this.lucore.axios.get( '/billing-plan-templates/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }


    async tasks(id) {

        return(
            this.lucore.axios.get( '/billing-plan-templates/'+id+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }


    async addTask(id,taskParams) {

        return(
            this.lucore.axios.post( '/billing-plan-templates/'+id+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }




}

export default LuCoreServiceBillingPlanTemplates
