class LuCoreServiceCategories {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list( params ) {

        return( 
            this.lucore.axios.get( '/categories', { params: params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in categories",e);
                return( e );

            })
        );
    }
    
    async get(categoryId) {

        return( 
            this.lucore.axios.get( '/categories/'+categoryId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in categories get",e);
                return( e );

            })
        );
    }
    

    /**
     * 
     * @param {name,parent_id} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/categories', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create category",e);
                return( e );

            })
        );
    }
    
    
    /**
     * Update the category
     * * @param {name} params 
     */
    async update(categoryId,params) {

        return( 
            this.lucore.axios.put( '/categories/'+categoryId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in category update",e);
                return( e );

            })
        );
    }

    
    
    /**
     * 
     * @param {categoryId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(categoryId,file) {
        return( 
            this.lucore.setPrimaryImage('categories',categoryId,file,"Profile")
        )
    }



}

export default LuCoreServiceCategories;