class LuCoreServiceImages {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list() {

        return( 
            this.lucore.axios.get( '/images', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in images",e);
                return( e );

            })
        );
    }

    
    async get(imageId, cacheable) {

        const cacheKey = 'LuCoreServiceImages_get_'+imageId;
        
        return( 

            this.lucore.cacheable( cacheKey, cacheable ).then( cachedResponse => {

                if( cachedResponse )
                    return( cachedResponse );

                return(
                    
                    this.lucore.axios.get( '/images/'+imageId, {})
                    .then(response => {
                        this.lucore.storeCache( cacheKey, response.data );
                        return( response.data );
                    })
                    .catch(e => {
                        this.lucore.logError("Error in images get",e);
                        return( {} );
        
                    })    
                )
                
            })

        )
    }




}

export default LuCoreServiceImages;