class LuCoreServiceBillingPlans {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async billingPlanClasses() {

        return(
            this.lucore.axios.get( '/billing-plans/billing-plan-classes', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlanClasses",e);
                return( e );

            })
        );
    }


    async billingCycleClasses() {

        return(
            this.lucore.axios.get( '/billing-plans/billing-cycle-classes', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingCycleClasses",e);
                return( e );

            })
        );
    }


    async list( params ) {

        return(
            this.lucore.axios.get( '/billing-plans', { params : params } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlans",e);
                return( e );

            })
        );
    }

    async get(billingPlanId) {

        return(
            this.lucore.axios.get( '/billing-plans/'+billingPlanId, {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlans get",e);
                return( e );

            })
        );
    }



    async invoiceLineItems(billingPlanId) {

        return(
            this.lucore.axios.get( '/billing-plans/'+billingPlanId+'/invoice-line-items', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoiceLineItems",e);
                return( e );
            })
        );
    }




    async comments(billingPlanId) {

        return(
            this.lucore.axios.get( '/billing-plans/'+billingPlanId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( e );
            })
        );
    }



    async addComment(billingPlanId,commentText) {

        return(
            this.lucore.axios.post( '/billing-plans/'+billingPlanId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( e );
            })
        );
    }

    async create(params) {

        return(
            this.lucore.axios.post( '/billing-plans', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create billingPlan",e);
                return( e );

            })
        );
    }


    async update(billingPlanId,params) {

        return(
            this.lucore.axios.put( '/billing-plans/'+billingPlanId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlan update",e);
                return( e );

            })
        );
    }


    async run(billingPlanId) {

        return(
            this.lucore.axios.put( '/billing-plans/'+billingPlanId+'/run', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlan update",e);
                return( e );

            })
        );
    }


    async stories(id,page) {

        return(
            this.lucore.axios.get( '/billing-plans/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }


    async tasks(id) {

        return(
            this.lucore.axios.get( '/billing-plans/'+id+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }


    async addTask(id,taskParams) {

        return(
            this.lucore.axios.post( '/billing-plans/'+id+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }




}

export default LuCoreServiceBillingPlans;
