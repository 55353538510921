class LuCoreServiceMassEmails {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    async list( params ) {

        return( 
            this.lucore.axios.get( '/mass-emails', { params : params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in mass emails",e);
                return( {} );

            })
        );
    }

    
    async get(massEmailId, cacheable) {

        const cacheKey = 'LuCoreServiceMassEmails_get_'+massEmailId;
        
        return( 

            this.lucore.cacheable( cacheKey, cacheable ).then( cachedResponse => {

                if( cachedResponse )
                    return( cachedResponse );

                return(
                    
                    this.lucore.axios.get( '/mass-emails/'+massEmailId, {})
                    .then(response => {
                        this.lucore.storeCache( cacheKey, response.data );
                        return( response.data );
                    })
                    .catch(e => {
                        this.lucore.logError("Error in mass emails get",e);
                        return( {} );
        
                    })    
                )
                
            })

        )
    }

    
    async comments(massEmailId) {

        return( 
            this.lucore.axios.get( '/mass-emails/'+massEmailId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(massEmailId,commentText) {

        return( 
            this.lucore.axios.post( '/mass-emails/'+massEmailId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }


    async tasks(massEmailId) {

        return( 
            this.lucore.axios.get( '/mass-emails/'+massEmailId+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in mass-emails",e);
                return( e );
            })
        );
    }

    
    /**
     * 
     * @param {*} massEmailId 
     * @param {title,description,due_at,class} taskParams 
     */
    async addTask(massEmailId,taskParams) {

        return( 
            this.lucore.axios.post( '/mass-emails/'+massEmailId+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }

    
    async notifications(massEmailId) {

        return( 
            this.lucore.axios.get( '/mass-emails/'+massEmailId+'/notifications', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in notifications",e);
                return( e );
            })
        );
    }

    
    async users(massEmailId) {

        return( 
            this.lucore.axios.get( '/mass-emails/'+massEmailId+'/users', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users",e);
                return( e );
            })
        );
    }

    
    async userCount(massEmailId) {

        return( 
            this.lucore.axios.get( '/mass-emails/'+massEmailId+'/users/count', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in userCount",e);
                return( e );
            })
        );
    }


    
    /**
     * Update the 
     * * @param {params} 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/mass-emails', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in mass-emails create",e);
                return( e );

            })
        );
    }

    
    /**
     * Update the 
     * * @param {massEmailId} params 
     */
    async update(massEmailId,params) {

        return( 
            this.lucore.axios.put( '/mass-emails/'+massEmailId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in mass-emails update",e);
                return( e );

            })
        );
    }

    /**
     * senn a test mass email
     * * @param {massEmailId} params 
     */
    async sendTest(massEmailId) {

        const params = {}

        return( 
            this.lucore.axios.put( '/mass-emails/'+massEmailId+'/send-test', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in mass-emails sendTest",e);
                return( e );

            })
        );
    }

    /**
     * kickOff a mass email
     * * @param {massEmailId} params 
     */
    async kickOff(massEmailId) {

        const params = {}

        return( 
            this.lucore.axios.put( '/mass-emails/'+massEmailId+'/kick-off', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in mass-emails kickOff",e);
                return( e );

            })
        );
    }



}

export default LuCoreServiceMassEmails;