class LuCoreServiceAccounts {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    
    
    async accountClasses() {

        return( 
            this.lucore.axios.get( '/accounts/account-classes', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in accountClasses",e);
                return( e );
            })
        );
    }

    /**
     * Acceptable params
     *   page (the page number)
     *   per_page (the number per page)
     *   query (a search query)
     */
    async list( params ) {

        return( 
            this.lucore.axios.get( '/accounts', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in accounts",e);
                return( {} );

            })
        );
    }

    
    async roles() {

        return( 
            this.lucore.axios.get( '/accounts/roles', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in roles",e);
                return( {} );

            })
        );
    }

    async get(accountId, cacheable) {

        const cacheKey = 'LuCoreServiceAccounts_get_'+accountId;
        
        return( 

            this.lucore.cacheable( cacheKey, cacheable ).then( cachedResponse => {

                if( cachedResponse )
                        return( cachedResponse );                        

                return( 
                    this.lucore.axios.get( '/accounts/'+accountId, {})
                    .then(response => {
                        this.lucore.storeCache( cacheKey, response.data.account );
                        return( response.data.account );
                    })
                    .catch(e => {
                        this.lucore.logError("Error in account",e);
                        return( {} );
        
                    })
                );    
            })
        )

    }

    
    async childAccounts(accountId) {

        return( 
            this.lucore.axios.get( '/accounts/'+accountId+'/child-accounts', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in childAccounts",e);
                return( {} );
            })
        );
    }


    async applications(accountId) {

        return( 
            this.lucore.axios.get( '/accounts/'+accountId+'/applications', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in applications",e);
                return( {} );
            })
        );
    }

    
    async comments(accountId) {

        return( 
            this.lucore.axios.get( '/accounts/'+accountId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return( {} );
            })
        );
    }

    
    
    async addComment(accountId,commentText) {

        return( 
            this.lucore.axios.post( '/accounts/'+accountId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( {} );
            })
        );
    }


    

    
    async following(accountId) {

        return( 
            this.lucore.axios.get( '/accounts/'+accountId+'/following', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in following",e);
                return( {} );
            })
        );
    }

    
    
    async follow(accountId) {

        return( 
            this.lucore.axios.post( '/accounts/'+accountId+'/following', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in follow",e);
                return( {} );
            })
        );
    }

    
    
    async removeFollowing(accountId) {

        return( 
            this.lucore.axios.delete( '/accounts/'+accountId+'/following' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeFollowing",e);
                return( {} );
            })
        );
    }


    
    
    async setIsDemo(id) {

        return( 
            this.lucore.axios.put( '/accounts/'+id+'/is-demo', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setIsDemo",e);
                return( {} );
            })
        );
    }

    
    
    async removeIsDemo(id) {

        return( 
            this.lucore.axios.delete( '/accounts/'+id+'/is-demo' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeIsDemo",e);
                return( {} );
            })
        );
    }




    async invoices(id,page) {

        return(
            this.lucore.axios.get( '/accounts/'+id+'/invoices', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoices",e);
                return(e);
            })
        );
    }


    async generateNextInvoice(id) {

        return(
            this.lucore.axios.post( '/accounts/'+id+'/invoices/generate-next-invoice')
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in invoices",e);
                return(e);
            })
        );

    }

    async cards(id) {

        return(
            this.lucore.axios.get( '/accounts/'+id+'/cards', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in cards",e);
                return(e);
            })
        );
    }


    async payments(id,page) {

        return(
            this.lucore.axios.get( '/accounts/'+id+'/payments', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in payments",e);
                return(e);
            })
        );
    }


    async addPayment(id,params) {

        return(
            this.lucore.axios.post( '/accounts/'+id+'/payments', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addPayment",e);
                return(e);
            })
        );
    }




    async billingPlans(id,page) {

        return(
            this.lucore.axios.get( '/accounts/'+id+'/billing-plans', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in billingPlans",e);
                return(e);
            })
        );
    }

    async addBillingPlan(id,params) {

        return(
            this.lucore.axios.post( '/accounts/'+id+'/billing-plans', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addBillingPlan",e);
                return(e);
            })
        );
    }

    async addBillingPlanFromTemplate(id,params) {

        return(
            this.lucore.axios.post( '/accounts/'+id+'/billing-plan-from-template', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addBillingPlan",e);
                return(e);
            })
        );
    }

    async stories(id,page) {

        return( 
            this.lucore.axios.get( '/accounts/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }
    
    async history(accountId) {

        return( 
            this.lucore.axios.get( '/accounts/'+accountId+'/history', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in history",e);
                return( {} );
            })
        );
    }

    

    
    async tasks(accountId) {

        return( 
            this.lucore.axios.get( '/accounts/'+accountId+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }

    
    /**
     * 
     * @param {*} accountId 
     * @param {title,description,due_at,class} taskParams 
     */
    async addTask(accountId,taskParams) {

        return( 
            this.lucore.axios.post( '/accounts/'+accountId+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }



    /**
     * 
     * @param {name,agency_id,website,default_currency} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/accounts', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create account",e);
                return( e );

            })
        );
    }

    
    /**
     * 
     * @param {name,agency_id,website,default_currency} params 
     */
    async update(accountId,params) {

        return( 
            this.lucore.axios.put( '/accounts/'+accountId+'/admin-update', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create account",e);
                return( e );

            })
        );
    }

    
    /**
     * 
     * @param {accountId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(accountId,file) {
        return( 
            this.lucore.setPrimaryImage('accounts',accountId,file,"Profile")
        )
    }

    

    
    async users(accountId) {

        return( 
            this.lucore.axios.get( '/accounts/'+accountId+'/users', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in accounts.users",e);
                return( {} );

            })
        );
    }
    
    /**
     * Update the account status
     * 
     */
    async setStatus(accountId,status) {

        const params = {
            status: status
        }

        return( 
            this.lucore.axios.put( '/accounts/'+accountId+'/setStatus', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in agency setStatus",e);
                return( {} );

            })
        );
    }

    /**
     * Update the account status
     * 
     */
     async setPublicCanJoin(accountId,role,code) {

        const params = {
            role: role,
            code: code
        }

        return( 
            this.lucore.axios.put( '/accounts/'+accountId+'/set-public-can-join', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in agency setPublicCanJoin",e);
                return( e );

            })
        );
    }
    
    
    /**
     * Add an Inventory Item Class
     * 
     */
    async addInventoryItemClass(accountId,inventoryItemClass) {

        const params = {
            inventory_item_class: inventoryItemClass
        }

        return( 
            this.lucore.axios.post( '/accounts/'+accountId+'/inventory-item-class', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in account addInventoryItemClass",e);
                return( {} );

            })
        );
    }

    
    /**
     * Add an Inventory Item Class
     * 
     */
    async removeInventoryItemClass(accountId,inventoryItemClass) {

        const params = {
            inventory_item_class: inventoryItemClass
        }

        return( 
            this.lucore.axios.put( '/accounts/'+accountId+'/inventory-item-class', params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in account addInventoryItemClass",e);
                return( {} );

            })
        );
    }



    async driveTemplates(id) {

        return(
            this.lucore.axios.get( '/accounts/'+id+'/drive-templates', {
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in driveTemplates",e);
                return(e);
            })
        );
    }





}

export default LuCoreServiceAccounts;
