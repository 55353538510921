class LuCoreServiceUsers {

    lucore;

    constructor( lucore ) {
        this.lucore = lucore;
    }

    /**
     * Acceptable params
     *   page (the page number)
     *   per_page (the number per page)
     *   query (a search query)
     */
    async list( params ) {

        return( 
            this.lucore.axios.get( '/users', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users list",e);
                return( {} );

            })
        );
    }

    
    /**
     * Acceptable params
     *   page (the page number)
     *   per_page (the number per page)
     *   query (a search query)
     */
     async invites( params ) {

        return( 
            this.lucore.axios.get( '/users/invites', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users invites",e);
                return( {} );

            })
        );
    }
    

    
    /**
     * Acceptable params
     *   page (the page number)
     *   per_page (the number per page)
     *   query (a search query)
     */
     async registrations( params ) {

        return( 
            this.lucore.axios.get( '/users/registrations', { params : params })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users registrations",e);
                return( {} );

            })
        );
    }
    
    async roles() {

        return( 
            this.lucore.axios.get( '/users/roles', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in roles",e);
                return( {} );

            })
        );
    }

    
    async featureToggles() {

        return( 
            this.lucore.axios.get( '/users/feature-toggles', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in featureToggles",e);
                return( {} );

            })
        );
    }



    async get(userId, cacheable) {

        const cacheKey = 'LuCoreServiceUsers_get_'+userId;
        
        return( 

            this.lucore.cacheable( cacheKey, cacheable ).then( cachedResponse => {

                if( cachedResponse )
                        return( cachedResponse );                        

                return( 
                    this.lucore.axios.get( '/users/'+userId, {})
                    .then(response => {
                        this.lucore.storeCache( cacheKey, response.data );
                        return( response.data );
                    })
                    .catch(e => {
                        this.lucore.logError("Error in users get",e);
                        return( e );

                    })
                );            
            })
        )

    }

    
    async current() {

        return( 
            this.lucore.axios.get( '/users/current', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {

                this.lucore.logError("Error in users current",e);

                return( e );

            })
        );
    }

    
    async stories(id,page) {

        return( 
            this.lucore.axios.get( '/users/'+id+'/stories', {
                params : {
                    page:page
                }
            })
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in stories",e);
                return(e);
            })
        );
    }
    
    
    async comments(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/comments', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in comments",e);
                return(e);
            })
        );
    }

    
    async addComment(userId,commentText) {

        return( 
            this.lucore.axios.post( '/users/'+userId+'/comments', { comment : commentText} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addComment",e);
                return( e );
            })
        );
    }

    
    

    
    async following(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/following', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in following",e);
                return( {} );
            })
        );
    }

    
    
    async follow(userId) {

        return( 
            this.lucore.axios.post( '/users/'+userId+'/following', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in follow",e);
                return( {} );
            })
        );
    }

    
    
    async removeFollowing(userId) {

        return( 
            this.lucore.axios.delete( '/users/'+userId+'/following' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeFollowing",e);
                return( {} );
            })
        );
    }



    
    async history(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/history', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in history",e);
                return( {} );
            })
        );
    }



    
    async tasks(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/tasks', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in tasks",e);
                return( e );
            })
        );
    }

    
    /**
     * 
     * @param {*} userId 
     * @param {title,description,due_at,class} taskParams 
     */
    async addTask(userId,taskParams) {

        return( 
            this.lucore.axios.post( '/users/'+userId+'/tasks', taskParams)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in addTask",e);
                return( e );
            })
        );
    }



    
    async resetToken(userId) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/token/reset', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in resetToken",e);
                return( {} );
            })
        );
    }


    
    async revokeToken(userId) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/token/revoke', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in revokeToken",e);
                return( {} );
            })
        );
    }




    /**
     * 
     * @param {name,account_id,email,mobile,roles} params 
     */
    async create(params) {

        return( 
            this.lucore.axios.post( '/users', params)
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in create user",e);
                return( e );
            })
        );
    }

    
    /**
     * Update the user
     */
    async update(userId,params) {

        return( 
            this.lucore.axios.put( '/users/'+userId, params )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users update",e);
                return( e );

            })
        );
    }

    
    /**
     * 
     * @param {userId,file} params `file` is from a file chooser component such as FileBrowserPopUp
     */
    async setPrimaryImage(userId,file) {
        return( 
            this.lucore.setPrimaryImage('users',userId,file,"Profile")
        )
    }

    
    /**
     * Update the user email address
     */
    async setEmail(userId,email) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/setEmail', { email : email } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users setEmail",e);
                return( e );

            })
        );
    }

    
    /**
     * Update the user mobile
     */
    async setMobile(userId,mobile) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/setMobile', { mobile_phone_number : mobile } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users setEmail",e);
                return( e );

            })
        );
    }




    
    /**
     * Update the user roles
     */
    async setRoles(userId,roles) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/setRoles', { roles : roles } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users setRoles",e);
                return( e );

            })
        );
    }

    
    
    /**
     * Update the user can log in
     */
    async setCanLogIn(userId,canLogIn) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/setCanLogIn', { can_log_in : canLogIn } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users setCanLogIn",e);
                return(e);

            })
        );
    }

    
    /**
     * options = key=>value set of options to update
     */
    async setOptions(userId,options) {

        const optionsString = JSON.stringify(options);

        return( 
            this.lucore.axios.put( '/users/'+userId+'/setOptions', { options : optionsString } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users setIsRegistered",e);
                return( e );

            })
        );
    }



    
    /**
     * Add the account for a user
     */
    async addAccount(userId,accountId,roles) {

        return( 
            this.lucore.axios.post( '/users/'+userId+'/account', { account_id : accountId, roles : roles } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users addAccount",e);
                return( e );

            })
        );
    }



    /**
     * Removes the account from a user
     */
    async deleteAccount(userId,accountId) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/account', { account_id : accountId } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users deleteAccount",e);
                return( e);

            })
        );
    }


    
    
    /**
     * Add the agency for a user
     */
    async addAgency(userId,agencyId,roles) {

        return( 
            this.lucore.axios.post( '/users/'+userId+'/agency', { agency_id : agencyId, roles : roles } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users addAgency",e);
                return( e );

            })
        );
    }



    /**
     * Removes the agency from a user
     */
    async deleteAgency(userId,agencyId) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/agency', { agency_id : agencyId } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users deleteAgency",e);
                return( e );

            })
        );
    }


    


    async addApplication(userId,applicationId,roles) {

        return( 
            this.lucore.axios.post( '/users/'+userId+'/application', { application_id : applicationId, roles : roles } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users addApplication",e);
                return( e );

            })
        );
    }


    async deleteApplication(userId,applicationId) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/application', { application_id : applicationId } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users deleteApplication",e);
                return( e);

            })
        );
    }





    /**
     * Get inventory feed providers for a user
     */
     async getInventoryFeedProviders(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/inventory-feed-providers', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users getInventoryFeedProviders",e);
                return( e );

            })
        );
    }


    
    /**
     * Add the an inventory feed providersfor a user
     */
    async addInventoryFeedProvider(userId,inventoryFeedProviderId,roles) {

        return( 
            this.lucore.axios.post( '/users/'+userId+'/inventory-feed-providers', { inventory_feed_provider_id : inventoryFeedProviderId, roles : roles } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users addInventoryFeedProvider",e);
                return(e );

            })
        );
    }



    /**
     * Removes the inventory InventoryFeedProvider from a user
     */
    async deleteInventoryFeedProvider(userId,inventoryFeedProviderId) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/inventory-feed-providers', { inventory_feed_provider_id : inventoryFeedProviderId } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users deleteInventoryFeedProvider",e);
                return( e );

            })
        );
    }



    
    async getNotificationsList(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/notifications', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users getNotificationsList",e);
                return( e );

            })
        );
    }   

    
    async getNotificationsListAll(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/notifications/all', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users getNotificationsList",e);
                return( e );

            })
        );
    }   


    
    
    async getVisitLogs(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/visits', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users getVisitLogs",e);
                return( e );

            })
        );
    }   


    
    async getV1ApiTokenLogs(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/v1ApiTokenLogs', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users getV1ApiTokenLogs",e);
                return( e );

            })
        );
    }   





    async getObjectTokensCreated(userId) {

        return(
            this.lucore.axios.get( '/users/'+userId+'/object-tokens-created', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users getObjectTokensCreated",e);
                return( e );

            })
        );
    }


    async getObjectTokens(params) {

        return(
            this.lucore.axios.get( '/users/object-tokens', {params:params})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users getTokens",e);
                return( e );

            })
        );
    }



    async lacGetObjectViews(userId) {

        return( 
            this.lucore.axios.get( '/users/'+userId+'/lac/object-views', {})
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users getLacObjectViews",e);
                return( e );

            })
        );
    }   

    
    /**
     * Record an object view
     */
    async lacRecordObjectView(userId,lid) {

        if( !userId )
            return

        if( !lid )
            return

        return( 
            this.lucore.axios.put( '/users/'+userId+'/lac/record-object-view', { lid : lid } )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in users deleteAccount",e);
                return(e );

            })
        );
    }


    
    async enableFeature(userId,featureCode) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/feature-toggles/'+featureCode+'/enable', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in enableFeature",e);
                return( {} );
            })
        );
    }

    async disableFeature(userId,featureCode) {

        return( 
            this.lucore.axios.put( '/users/'+userId+'/feature-toggles/'+featureCode+'/disable', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in disableFeature",e);
                return( {} );
            })
        );
    }

    async setIsDemo(id) {

        return( 
            this.lucore.axios.put( '/users/'+id+'/is-demo', {} )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in setIsDemo",e);
                return( {} );
            })
        );
    }

    
    
    async removeIsDemo(id) {

        return( 
            this.lucore.axios.delete( '/users/'+id+'/is-demo' )
            .then(response => {
                return( response.data );
            })
            .catch(e => {
                this.lucore.logError("Error in removeIsDemo",e);
                return( {} );
            })
        );
    }


}

export default LuCoreServiceUsers;
